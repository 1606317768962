@mixin card($c: #fff) {
	padding: 10px;
	display: block;
	color: inherit;
	background: $c;
	position: relative;
	margin-bottom: 20px;
	text-decoration: none;
	border-radius: $matBorderRadius;
	@extend %animateFast;
	@include box-shadow("0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)");
	&.hover {
		@include box-shadow("0 6px 4px -8px rgba(0,0,0,.2),0 8px 8px 0 rgba(0,0,0,.14),0 2px 10px 0 rgba(0,0,0,.12)");
	}
	&.transparent {
		background: transparent;
	}
	&.magnet {
		&-x {
			border-radius: 0;
			&:first-child{ border-radius: $matBorderRadius 0 0 $matBorderRadius; }
			&:last-child { border-radius: 0 $matBorderRadius $matBorderRadius 0; }
		}
		&-y {
			border-radius: 0;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			&:first-child{ border-radius: $matBorderRadius $matBorderRadius 0 0; }
			&:last-child { border-radius: 0 0 $matBorderRadius $matBorderRadius; }
		}
	}
	
	// .header, .footer { overflow: hidden; }
	.cardHeader, .cardFooter, .cardContent {
		display: block;
		position: relative;
		@include clearfix();
	}

	.cardHeader {
		padding: 10px;
		margin: -10px -10px 0;
		border-radius: $matBorderRadius $matBorderRadius 0 0;
		.actions {
			top: 0;
			height: 100%;
			position: absolute;
			&.lside { left: 0; }
			&.rside { right: 0; text-align: right; }
		}
	}

	.cardContent {
		margin: 0 -10px;
		padding: 0 10px;
		.row {
			margin: 0 -10px;
			& > .table-responsive{ margin: 0 -1px; }
		}
	}

	.cardFooter {
		padding: 7px 10px;
		margin: 10px -10px -10px;
		border-radius: 0 0 $matBorderRadius $matBorderRadius;
	}
}

.card, %card {
	@include card;
	@include clearfix();
	&.noShadow{
		@include box-shadow(none);
	}
	&.hover,
	&:hover, &:focus, &:active {
		text-decoration: none;
		@include box-shadow("0 6px 4px -8px rgba(0,0,0,.2),0 8px 8px 0 rgba(0,0,0,.14),0 2px 10px 0 rgba(0,0,0,.12)");
	}
}

.card {
	&.hasFooter {
		padding-bottom: 50px;
		.cardFooter {
			left: 0;
			bottom: 0;
			margin: 0;
			width: 100%;
			position: absolute;
		}
	}
}

.cards {
	padding: 10px;
	@extend %notaList !optional;
	& > li,
	& > article {
		background: #fff;
		@extend %card !optional;
	}
	// &.invert {
	// 	& > li,
	// 	& > article {
	// 		color: #fff;
	// 		background: #0F455F;
	// 	}
	// }
}

.f-row, .flex {
	.card { height: 100%; }
}