.dropdown-right {
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.dialog {
  font-size: 20px;
  @include font($fontL);
  &.success {
    .dialog-message {
      // min-height: 265px;
      // padding: 28px 60px;
      padding: 28px 30px;
      @media only screen and (max-width: 400px) {
        // padding: 28px 30px;
        text-align: center;
      }
      a {
        background: #fff;
      }
    }
  }
  .btn-mtl {
    font-size: 17px;
    @include font($font);
  }
}
