$mat: (
    font: $prFont,
    fontM: map-get($theme, fontM),
    color: $prColor,
    ctrlGutter: 1em,
    border: rgba(#000, 0.4),
    placeholder: rgba(#000, 0.42),
    kind: (
        primary: map-get($color, primary),
        accent: map-get($color, accent),
        white: #fff,
        default: #fff,
        info: #039BE5,
        warn: #FFB74D,
        error: #B92D00,
        success: #009688
    )
) !default;

$matBorderRadius: 4px;
$fw-m: map-get($mat, fontM) !default;

$matColor: map-get($mat, kind);
$placeholderColor: map-get($mat, placeholder) !default;
$labelFocus: map-get($mat, color) !default;
$errorColor: map-get($matColor, error) !default;
$successColor: map-get($matColor, success) !default;
$ctrlpadding: map-get($mat, ctrlGutter)  !default;
$matLblFont: map-get($mat, font) !default;
$matLblFontFocus: map-get($mat, fontM) !default;
