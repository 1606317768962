.nmToast,
.snackbar {
    bottom: 0;
    #{$left}: 0;
    #{$right}: 0;
    width: 100%;
    color: #fff;
    z-index: 2000;
    margin: 0 auto;
    cursor: default;
    position: fixed;
    min-height: 48px;
    max-height: 80px;
    visibility: hidden;
    pointer-events: none;
    will-change: transform;
    background-color: #323232;
    transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
    transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.25s cubic-bezier(0.4, 0, 1, 1);

    @include prefix((transform: translate(0, 80px)), webkit ms);
    @include justify-content(space-between);

    @extend %flexbox;

    &-text {
        padding: 14px 12px 14px 24px;
    }

    &-action {
        @include font($fw-m);
        background: transparent;
        text-transform: uppercase;
        padding: 14px 24px 14px 12px;
        color: map-get($matColor, info); //#ff4081;
        transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
        transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);

        @extend .btn !optional;

        &:hover,
        &:focus,
        &:active {
            color: map-get($matColor, info); //#ff4081;
       
        }
    }

    &.active {
        visibility: visible;
        pointer-events: all;

        @include prefix((transform: translate(0, 0)), webkit ms);
    }

    @media only screen and (min-width: 480px) {
        min-width: 288px;
        max-width: 568px;
    }

    &[data-placement="top"] {
        top: 0px;
        bottom: auto;

        @include prefix((transform: translate(0, -80px)), webkit ms);

        &.active {
            @include prefix((transform: translate(0, 0)), webkit ms);
        }
    }

    &[data-placement="bottom"] {
        top: auto;
        bottom: 0px;

        @include prefix((transform: translate(0, 80px)), webkit ms);

        &.active {
            @include prefix((transform: translate(0, 0)), webkit ms);
        }
    }
}
