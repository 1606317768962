::-moz-selection {
  background-color: rgba($prColor, 0.3);
}

::selection {
  background-color: rgba($prColor, 0.3);
}

* {
  @include scrollbar(8px, map-get($theme, color), #878787);
  &:before,
  &:after {
    pointer-events: none;
  }
}

html,
body {
  width: 100%;
  width: 100vw;
  // height: 100%;
  // height: 100vh;
  overflow: scroll; // /* has to be scroll, not auto
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; // background: map-get($theme, bg);
  font-size: $baseFontSize;
  line-height: $baseLineHgt;
  @if variable-exists(minWidth) {
    min-width: $minWidth;
  }
  @if variable-exists(maxWidth) {
    max-width: $maxWidth;
  }

  @include scrollbar(8px, map-get($theme, color), #878787);
}

body {
  font-weight: 400; // line-height: em(33px);
  color: map-get($theme, text);
  background: map-get($theme, bg);
  font-family: map-get($theme, fontFamily);

  // font-style: normal;
  // font-kerning: normal;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // -webkit-backface-visibility: hidden;
}

a,
button {
  & > * {
    pointer-events: none;
  }
}

a {
  color: $prLink;
  &,
  & > * {
    outline: none;
  }
  & > * {
    pointer-events: none;
  }
  &,
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }
  // &:hover,
  // &:active,
  // &:focus,
  // &.active {
  //   color: map-get($theme, color);
  // }
}

b,
strong {
  font-weight: normal;
  @include font(map-get($theme, fontB));
}

svg {
  &.defs {
    width: 0;
    height: 0;
    position: absolute;
  }
}

fieldset {
  padding: remc(7px) remc(20px);
  border-radius: remc($brdRad);
  border: rgba(#000, 0.36) remc(1px) solid;
}

.container-fluid {
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1260px;
}

// :not(input):not(textarea) {
//   @extend .nilSelect !optional;
// }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
  font-weight: normal;
}

h1,
.h1 {
  font-size: em(45px);
}

h2,
.h2 {
  font-size: em(35px);
}

h3,
.h3 {
  font-size: em(30px);
}

h4,
.h4 {
  font-size: em(25px);
}

h5,
.h5 {
  font-size: em(20px);
}

h6,
.h6 {
  font-size: em(18px);
}

img {
  max-width: 100%;
  height: auto !important;
}

.fw,
.fh {
  position: relative;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

video.asBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next,
button.owl-dot {
  background: transparent;
  border: 0 none transparent;
}

@import "utilities";

// Custom
// @media only screen and (min-width: $screen-xl) {
//   .container {
//     max-width: $container-xl-desktop;
//   }
// }
