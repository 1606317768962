footer.footer {
  width: 100%;
  padding: 30px 0px 15px;
  color: map-get($theme, footer-text);
  background: map-get($theme, footer-bg);
  ul.links {
    font-size: 18px;
    @include notaList;
    li {
      margin-bottom: 20px;
      // padding: 20px 0px 0px;
      a {
        color: map-get($color, primary);
        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      padding: 0 6px;
      margin-bottom: 35px;
    }
  }
  .about {
    font-size: 14px;
    max-width: 680px;
    padding-left: 30px;
    padding-right: 20px;
    p {
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .link {
    color: map-get($theme, footer-link);
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      color: rgba(map-get($theme, footer-link), 0.8);
    }
  }
}
