// @import
// 	"../plugins/NitroMaterial/style";

// /* **********************
// /* ******** OR **********
// /* Choose a custom buid
// /* **********************/
@import "../plugins/NitroMaterial/matVariables"; // Don't edit this file directly
// if Overrides with vars change below this line

// LAB: future-release
// $mat: map-merge($mat, (
//     fontM: map-get($theme, fontB)
// ));

$fw-m: map-get($theme, fontB);

// @import "../plugins/NitroMaterial/common";
@import "../plugins/NitroMaterial/matComponents";
@import "../plugins/NitroMaterial/dialog";
@import "../plugins/NitroMaterial/snackBar";
@import "../plugins/NitroMaterial/matCard";
// @import "../plugins/NitroMaterial/matTabs";
// @import "../plugins/NitroMaterial/matList";
// @import "../plugins/NitroMaterial/pagination";
// @import "../plugins/NitroMaterial/matDataTable";

/* THEME */
// @import "../plugins/NitroMaterial/theme/primary";
// @import "../plugins/NitroMaterial/theme/invert";