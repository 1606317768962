// ------------------------ //
// ***** WEIGHT CHART ***** //
// ------------------------ //
// #region -= WEIGHT CHART
// 100    Extra Light or Ultra Light
// 200    Light / Thin
// 300    Book or Demi / Thin
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black
// #endregion -= WEIGHT CHART

@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap");

/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=0ae00166-42cb-47dd-8559-e16674124c1a");
@font-face {
  font-family: "Neue Helvetica W05_n3";
  src: url("#{$fonts}/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix") format("eot");
}
@font-face {
  font-family: "Neue Helvetica W05";
  src: url("#{$fonts}/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix");
  src: url("#{$fonts}/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix") format("eot"),
    url("#{$fonts}/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),
    url("#{$fonts}/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff"),
    url("#{$fonts}/fdf10d5e-4ce0-45e5-a6c1-5eaff6cb1c11.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Neue Helvetica W05_n4";
  src: url("#{$fonts}/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("eot");
}
@font-face {
  font-family: "Neue Helvetica W05";
  src: url("#{$fonts}/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
  src: url("#{$fonts}/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("eot"),
    url("#{$fonts}/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),
    url("#{$fonts}/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"),
    url("#{$fonts}/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Neue Helvetica W05_n5";
  src: url("#{$fonts}/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot");
}
@font-face {
  font-family: "Neue Helvetica W05";
  src: url("#{$fonts}/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix");
  src: url("#{$fonts}/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot"),
    url("#{$fonts}/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),
    url("#{$fonts}/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"),
    url("#{$fonts}/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Helvetica W05_n7";
  src: url("#{$fonts}/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("eot");
}
@font-face {
  font-family: "Neue Helvetica W05";
  src: url("#{$fonts}/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix");
  src: url("#{$fonts}/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("eot"),
    url("#{$fonts}/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),
    url("#{$fonts}/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"),
    url("#{$fonts}/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
