.hgLgtCards {
  padding: 42px 0;
  .card {
    margin-bottom: 34px;
    &-footer {
      .btn-md {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 200px;
        @include font($font);
        text-transform: uppercase;
        &:not(:hover):not(:active):not(:focus) {
          background: transparent;
        }
      }
    }
    // @media only screen and (max-width: 1199px) {
    //   height: auto;
    // }
  }
  @media only screen and (min-width: 1200px) {
    .img {
      text-align: right;
      &.t-c {
        text-align: center;
      }
    }
  }
}

.headClr {
  margin-bottom: 22px;
  @include font($fontM);
  color: map-get($theme, textL);
  @media only screen and (max-width: 576px) {
    font-size: 32px;
  }
}

.cont {
  font-size: 24px;
  line-height: 36px;
  @include font($fontL);
  color: map-get($theme, textL);
  @media only screen and (max-width: 991px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
}

.duration {
  font-size: 17px;
  color: map-get($theme, accent);
  @include font($fontM);
}

.line {
  display: none;
  border-bottom: 2px solid map-get($theme, textL);
  width: 100%;
  margin: 15px;
  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.f-italic {
  font-style: italic;
}

.iconTable {
  height: 100%;
  display: flex;
  // padding: 42px 0;
  flex-direction: column;
  .iconHead {
    i {
      font-size: 66px;
      padding: 15px 35px;
      text-align: center;
      &:before {
        width: 127px;
        height: 127px;
        display: block;
        padding: 30px 0;
        border-radius: 50%;
        background-color: map-get($theme, write);
      }
    }
  }
  .icondesp {
    padding: 15px 0 0;
    .h5 {
      margin-bottom: 10px;
    }
    .create {
      @media only screen and (min-width: 1200px) {
        padding-top: 50px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1200px) {
        padding-top: 25px;
      }
    }
  }
  .iconFooter {
    margin-top: auto;
    padding: 20px 0 0;

    .btn-md {
      width: 100%;
      display: block;
      max-width: 200px;
      @include font($font);
      text-transform: uppercase;
      // &:not(:hover):not(:active):not(:focus) {
      //   background: transparent;
      // }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 22px 0;
    text-align: center;
    .iconFooter {
      .btn-md {
        margin: 0 auto;
      }
    }
  }
}
