label {
  @include font($font);
  &[for] {
    cursor: pointer;
  }
}

input {
  &[type="checkbox"] {
    &.flatBtn {
      padding: 0;
      width: 1px;
      height: 1px;
      margin: -1px;
      display: none;
      border: 0 none;
      overflow: hidden;
      clip: rect(0 0 0 0);
      // position: absolute;
      & + label {
        width: 201px;
        height: 101px;
        padding: 20px;
        display: flex;
        color: #878787;
        text-align: center;
        align-items: center;
        background: map-get($theme, gray);
        @extend %animateFast;
        span {
          margin: 0 auto;
        }
      }
      &:checked {
        & + label {
          color: map-get($color, light);
          background: map-get($theme, grayD);
        }
      }
    }
  }
}

.btn {
  line-height: 1em;
  @extend %animateFast;
  &-md {
    height: 50px;
    padding: 14px;
    font-size: 18px;
  }
  &-lg {
    height: 58px;
    padding: 18px;
    font-size: 17px;
    min-width: 228px;
  }

  @each $k, $v in $color {
    &-#{$k} {
      background: $v;
      color: map-get($cont, $k);
      &:hover,
      &:focus,
      &:active {
        color: map-get($cont, $k);
        background: darken($v, 6.5%);
      }
    }
    &-outline-#{$k} {
      border-width: 1px;
      border-style: solid;
      background: map-get($color, light);
      &.lighten {
        background: rgba($v, 0.09);
        &:hover,
        &:focus,
        &:active {
          background: rgba($v, 1);
          color: map-get($cont, $k);
        }
      }
    }
  }
}

.input {
  &-group {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &-addon {
      padding: remc(6px) remc(12px);
      & > select {
        border-radius: 0;
        height: remc(42px);
        border: 0 none transparent;
        padding: remc(6px) remc(12px);
        margin: remc(-8px) remc(-12px);
      }
    }
  }
}

.form {
  &-group {
    margin-bottom: remc(15px);
    &:not(.form-error) {
      .form-text {
        opacity: 0;
        display: none;
        font-size: 15px;
      }
    }
    &.form-error {
      .form-control,
      .SumoSelect .CaptionCont {
        border-color: map-get($color, danger);
      }

      // .form-text {
      // }
    }
  }
  input[type="checkbox"].custom + label::after {
    display: none;
  }

  &-control {
    height: 50px;
    font-size: 18px;
    padding: 14px 20px;
    border-radius: 2px;
    border-color: transparent;
    background: map-get($theme, gray);
    &-static {
      @include font($fontB);
    }
    &:not(output):-moz-ui-invalid {
      box-shadow: none;
    }
  }

  &-text {
    font-size: 15px;
  }

  .SumoSelect {
    height: 50px;
    font-size: 18px;
    border-radius: 2px;
    &.open {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    & > .optWrapper,
    & > .CaptionCont {
      // box-shadow: none;
      border-color: transparent;
      background: map-get($theme, gray);
      // border-left: 10px solid map-get($theme, color);
      & > .options li.opt {
        padding: 4px 20px;
      }
    }
    & > .CaptionCont {
      padding: 4px 4px 4px 20px;
      & > label {
        width: 30px;
        & > i {
          font-size: 30px;
        }
      }
    }
    &.accent,
    &.required {
      & > .optWrapper,
      & > .CaptionCont {
        border-left-color: map-get($theme, accent);
      }
    }
    & > .CaptionCont {
      &,
      & > span {
        min-height: 42px;
        line-height: 42px;
      }
    }
    & > .optWrapper {
      border-radius: 0;
      top: calc(100% - 1px);
    }
  }
  // .required {
  //   border-left-color: map-get($theme, accent);
  // }
  // input {
  //   &[type="tel"],
  //   &[type="text"],
  //   &[type="email"] {
  //     height: 50px;
  //     border-left: 10px solid map-get($theme, accent);
  //   }
  // }
  // &-horizontal .control-label {
  //   padding-top: remc(4px);
  // }
}

input[type="checkbox"].custom:checked + label::before {
  color: map-get($color, primary);
}

.copyText {
  position: relative;
  input {
    padding: 0;
    width: 100%;
    padding: 8px 10px;
    padding-right: 2em;
    border-radius: 5px;
    text-overflow: ellipsis;
    background: rgba(map-get($color, accent), 0.16);
    border: 1px solid rgba(map-get($color, accent), 0.3);
    &,
    &:hover,
    &:focus,
    &:active {
      outline: 0 none;
    }
  }
  .ico {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: stretch;
    button {
      width: 2em;
      height: 100%;
      display: block;
      background: transparent;
      border: 0 none transparent;
    }
  }
}

// .rating {
//   span.star {
//     color: rgba(#929292, 0.5);
//     &.active,
//     &.active ~ span.star {
//       color: #f7ae00;
//     }
//   }
// }
