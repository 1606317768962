.link {
  @include font($fontB);
  color: map-get($color, primary);
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: darken(map-get($color, primary), 6.5%);
  }
  &.under {
    text-decoration: underline;
  }
}

.bootstrap-datetimepicker-widget .datepicker {
  width: 100%;
  &:before {
    display: none;
  }
}

.card {
  padding: 0;
  display: flex;
  flex-direction: column;
  &-header,
  &-content,
  &-footer {
    padding: 5px 10px;
  }
  &-footer {
    margin-top: auto;
  }
  &.rytaryGlow {
    height: 100%;
    box-shadow: 0 6px 12px rgba(map-get($color, dark), 0.2);
    @include linear-gradient(map-get($color, light) 30%, map-get($theme, gray) 100%);
    .card {
      &-header {
        padding: 25px;
        text-align: center;
        color: map-get($theme, write);
        box-shadow: 0 5px 11px rgba(map-get($color, dark), 0.2);
        @include linear-gradient(
          -120.52deg,
          map-deep-get($theme, cardHeadGrad, 0) 0%,
          map-deep-get($theme, cardHeadGrad, 1) 105%
        );
        @media only screen and (max-width: 576px) {
          .h2 {
            font-size: remc(32px);
          }
        }
      }
      &-content {
        font-size: 20px;
        padding: 15px 25px 0;
        @include font($fontL);
      }
      &-footer {
        padding: 0 5px 28px;
      }
    }
    &.blinkrxCard {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .card-header {
        background: transparent;
        box-shadow: none;
      }
      .card-content {
        font-size: 35px;
        line-height: normal;
        color: map-get($theme, textD);
        @include font($fontL);
        padding: 20px 45px;
      }
      .card-header,
      .card-footer {
        padding: 20px 30px;
      }
      .card-footer {
        margin-top: 0;
      }
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        .card-content {
          font-size: 30px;
          padding: 15px 38px;
        }
      }
    }
  }
}

.videoPlayer {
  cursor: pointer;
  position: relative;
  display: inline-block;
  // background: map-get($color, dark);
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    margin: auto;
    display: block;
    font-size: 30px;
    line-height: 36px;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    @extend %animateFast;
    align-content: center;
    content: $i-play_arrow;
    background: transparent;
    color: map-get($color, light);
    font-family: "#{$icomoon-font-family}";
    border: 2px solid map-get($color, light);
    box-shadow: 0 0 20px rgba(map-get($color, dark), 0.5);
    text-shadow: 0 0 4px rgba(map-get($color, dark), 0.9);
  }
  &:hover,
  &:focus,
  &:active {
    &:before {
      transform: scale(1.2);
    }
  }
  img {
    width: 100%;
  }
  // &.darkPlay {
  //   &:before {
  //     color: map-get($color, dark);
  //     border-color: map-get($color, dark);
  //     box-shadow: 0 0 20px rgba(map-get($color, light), 0.5);
  //     text-shadow: 0 0 4px rgba(map-get($color, light), 0.9);
  //   }
  // }
}

.owl-carousel {
  .owl-dots {
    .owl-dot {
      span {
        box-shadow: none;
        border: 1px solid map-get($color, dark);
      }
      &.active,
      &:hover {
        span {
          box-shadow: none;
          background: map-get($color, dark);
        }
      }
    }
  }
}

// [data-toggle="collapse"].collapser {
//   display: block;
//   position: relative;
//   &:after,
//   &:before {
//     content: "";
//     position: absolute;
//     @extend %animateFast;
//   }
//   &:before {
//     @extend .i;
//     z-index: 1;
//     bottom: remc(0px);
//     content: $i-add_circle;
//     color: map-get($theme, color);
//   }
//   &[aria-expanded="true"] {
//     &:before {
//       content: $i-remove_circle;
//     }
//   }
//   &.withArrow {
//     font-size: remc(29px);
//     margin-top: remc(50px);
//     margin-bottom: remc(35px);
//     padding-bottom: remc(35px);
//     line-height: em(30px, 29px);
//     color: map-get($theme, color);
//     @include font(map-get($theme, fontB));
//     border-bottom: remc(7px) solid map-get($theme, brdr);
//     &:after,
//     &:before {
//       right: remc(80px);
//     }
//     &:before {
//       margin-right: remc(24px);
//       content: $i-keyboard_arrow_down;
//       font-size: em(26.7px, 29px);
//     }
//     &:after {
//       top: 100%;
//       z-index: 0;
//       width: remc(45px);
//       height: remc(45px);
//       background: #fff;
//       border-style: solid;
//       border-color: map-get($theme, brdr);
//       border-width: 0 remc(7px) remc(7px) 0;
//       @include prefix(
//         (
//           transform: rotate(37deg) skewX(-20deg),
//           transform-origin: right top
//         ),
//         webkit ms
//       );
//       margin-top: remc(5px);
//     }
//     &[aria-expanded="true"] {
//       &:before {
//         content: $i-keyboard_arrow_down;
//         bottom: remc(-32px);
//         @include prefix(
//           (
//             transform: rotate(180deg)
//           ),
//           webkit ms
//         );
//       }
//       &:after {
//         top: 100%;
//         margin-top: remc(3px);
//         @include prefix(
//           (
//             transform: rotate(37deg) skewX(-20deg) scale(-1) translate(remc(45px), remc(-45px))
//           ),
//           webkit ms
//         );
//       }
//     }
//   }
//   &.plusMinus {
//     &:before {
//       content: $i-add_circle;
//     }
//     &[aria-expanded="true"] {
//       &:before {
//         content: $i-remove_circle;
//       }
//     }
//   }
// }
