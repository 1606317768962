.descargar {
  margin: 35px 0;
  font-size: 16px;
  text-align: center;
  @include font($fontB);
  text-decoration: underline;
  color: map-get($color, primary);
  a {
    &:hover,
    &:active,
    &:focus {
      color: rgba(map-get($color, primary), 0.9);
    }
  }
}
.toolbtn {
  width: 100%;
  font-size: 17px;
  max-width: 293px;
  @media only screen and (max-width: 767px) {
    padding: 11px 0;
  }
}
.providebtn {
  width: 100%;
  font-size: 17px;
  max-width: 293px;
  @media only screen and (min-width: 1200px) {
    max-width: 219px;
  }
  // @media only screen and (max-width: 767px) {
  //   font-size: 15px;
  // }
}
.espbtn {
  @media only screen and (max-width: 767px) {
    margin-top: 20px;
    // text-align: center;
  }
  // @media only screen and (max-width: 576px) {
  //   margin-top: 20px;
  // }
}
@media only screen and (max-width: 1200px) {
  .img {
    text-align: center;
  }
}
@media only screen and (max-width: 991px) {
  .img {
    max-width: 320px;
    margin: 0 auto;
  }
}
.banner {
  &.educate {
    padding: 30px 0 15px;
    margin-top: 0 !important;
  }
}
.welcomeHead {
  margin-bottom: 15px;
  @include font($fontM);
  color: map-get($theme, header-text);
}
.brochure {
  margin-top: 20px;
  padding: 40px 35px 20px;
  figure {
    margin: 0 auto;
    max-width: 120px;
    text-align: center;
    &.brwidth {
      max-width: 170px;
    }
  }
  @media only screen and (max-width: 992px) {
    padding: 20px 15px;
    // figure {
    //   &.brwidth {
    //     max-width: 187px;
    //   }
    // }
  }
  .title-br {
    margin-bottom: 10px;
    color: map-get($theme, text);
  }
  .content-br {
    font-size: 20px;
    line-height: 30px;
    @include font($fontL);
    color: map-get($theme, text);
  }
  .links {
    margin: 20px 0;
    display: block;
    font-size: 16px;
    text-align: center;
    @include font($fontB);
    text-decoration: underline;
  }
  @media only screen and (max-width: 991px) {
    figure {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .h4 {
      font-size: 21px;
    }
    .content-br {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.downloads {
  padding-bottom: 175px !important;
  .btn-md {
    font-size: 20px;
    min-width: 187px;
    padding: 7px 11px;
    line-height: 30px;
    text-transform: uppercase;
    // @media only screen and (max-width: 991px) {
    //   display: block;
    //   margin: 0 auto;
    // }
    @media only screen and (max-width: 567px) {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}
.telemedicine {
  // bottom: 135px;
  position: relative;
  // margin-bottom: -80px;
  // position: absolute;
  .title {
    margin-bottom: 10px;
    color: map-get($theme, text);
  }
  .icons {
    i {
      font-size: 66px;
      text-align: center;
      padding: 15px 35px;
      margin-bottom: 25px;
      &:before {
        width: 127px;
        height: 127px;
        display: block;
        padding: 30px 0;
        border-radius: 50%;
        background-color: map-get($theme, write);
      }
    }
  }
  .btn-o {
    font-size: 17px;
    color: map-get($color, primary);
    border-color: map-get($color, primary);
  }
  @media only screen and (max-width: 991px) {
    .h4 {
      font-size: 24px;
    }
    .notes {
      font-size: 16px;
    }
    .bgGray {
      padding: 40px 15px !important;
    }
    .btn-o {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
    .icons {
      text-align: center;
    }
  }
}
.btnLink {
  margin: 5px 0;
  display: block;
  font-size: 16px;
  @include font($fontB);
  text-decoration: underline;
  color: map-get($theme, header-text);
  &:hover {
    color: map-get($theme, accent);
  }
  &._left {
    padding: 0;
    text-align: left;
    height: auto;
  }
}
