/**!
 * @name NitroDialog - Plugin
 * @version 1.0.0
 * @author Ajith S Punalur
 * Licence: MIT
 **/

dialog,
.dialog {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: none;
    border: 0 none;
    overflow: auto;
    position: fixed;
    max-width: 80vw;
    max-height: 90vh;
    visibility: hidden;
    background: #fff;

    border-radius: 2px;
    @include prefix((transform: translate(-50%, -50%)), webkit ms);
    @include box-shadow("0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12)");

    &[open],
    &.open {
        display: block;
        visibility: visible;
    }

    &Backdrop {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        position: fixed;
        visibility: hidden;
        pointer-events: none;

        // @extend %animateFast;
        &.active,
        &.transparent {
            display: block;
            visibility: visible;
            pointer-events: all;
        }

        &.active {
            background: rgba(#000, 0.8);
        }
    }

    &-header,
    &-message,
    &-footer {
        @include clearfix;
    }

    &-message {
        padding: 24px;

        // margin-bottom: 24px;
   
    }

    &-footer {
        padding: 0px 24px 10px;
        margin-top: 0px; // -24px -24px;
   
    }

    .dialogClose {
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        padding: 3px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        background: #e20000;
        text-decoration: none;
    }

    &.transparent {
        background: transparent;

        .dialogClose {
            top: -10px;
            right: -10px;
        }
    }

    @media only screen and (max-width: 767px) {
        max-width: 90vw;
        max-height: 90vh;
    }
}
