$icomoon-font-family: "icon" !default;
$icomoon-font-path: "fonts" !default;

$i-add: "\ea2c";
$i-alternate_email: "\e987";
$i-fingerprint: "\ec60";
$i-send_and_archive: "\ed14";
$i-attach_email: "\ed8d";
$i-electric_bike: "\edba";
$i-electric_car: "\edbb";
$i-electric_moped: "\edbc";
$i-electric_rickshaw: "\edbd";
$i-electric_scooter: "\edbe";
$i-electrical_services: "\edbf";
$i-elevator: "\edc0";
$i-handyman: "\edd0";
$i-mark_email_read: "\edeb";
$i-mark_email_unread: "\edec";
$i-qr_code: "\ee2b";
$i-qr_code_scanner: "\ee2c";
$i-verified: "\ee5f";
$i-wash: "\ee62";
$i-6_ft_apart: "\ee6c";
$i-clean_hands: "\ee6e";
$i-connect_without_contact: "\ee6f";
$i-coronavirus: "\ee70";
$i-elderly: "\ee71";
$i-follow_the_signs: "\ee72";
$i-leave_bags_at_home: "\ee73";
$i-masks: "\ee74";
$i-reduce_capacity: "\ee75";
$i-sanitizer: "\ee76";
$i-send_to_mobile: "\ee77";
$i-sick: "\ee78";
$i-facebook: "\ee7c";
$i-align_horizontal_center: "\ee86";
$i-align_horizontal_left: "\ee87";
$i-align_horizontal_right: "\ee88";
$i-align_vertical_bottom: "\ee89";
$i-align_vertical_center: "\ee8a";
$i-align_vertical_top: "\ee8b";
$i-horizontal_distribute: "\ee8c";
$i-qr_code_2: "\ee8d";
$i-vertical_distribute: "\ee8f";
$i-download: "\e900";
$i-code-telemed: "\ea17";
$i-covid-telemed: "\ea19";
$i-guide: "\ea20";
$i-hcp: "\ea22";
$i-patient-telemed: "\ea24";
$i-close: "\e901";
$i-play: "\e902";
$i-light: "\e903";
$i-dark: "\e904";
$i-error: "\e905";
$i-error_outline: "\e906";
$i-warning: "\e907";
$i-report_problem: "\e907";
$i-add_alert: "\e908";
$i-notification_important: "\e909";
$i-av_timer: "\e90a";
$i-fast_forward: "\e90b";
$i-loopsync: "\e90c";
$i-moviemovie_creation: "\e90d";
$i-library_add: "\e90e";
$i-queue: "\e90e";
$i-add_to_photos: "\e90e";
$i-library_music: "\e90f";
$i-pause: "\e910";
$i-pause_circle_filled: "\e911";
$i-pause_circle_outline: "\e912";
$i-play_arrow: "\e913";
$i-play_circle_filled: "\e914";
$i-play_circle_outline: "\e915";
$i-playlist_add: "\e916";
$i-queue_music: "\e917";
$i-radio: "\e918";
$i-repeat: "\e919";
$i-repeat_one: "\e91a";
$i-replay: "\e91b";
$i-skip_next: "\e91c";
$i-skip_previous: "\e91d";
$i-surround_sound: "\e91e";
$i-video_collection: "\e91f";
$i-videocam: "\e920";
$i-videocam_off: "\e921";
$i-airplay: "\e922";
$i-forward_10: "\e923";
$i-forward_30: "\e924";
$i-forward_5: "\e925";
$i-replay_10: "\e926";
$i-replay_30: "\e927";
$i-replay_5: "\e928";
$i-fiber_dvr: "\e929";
$i-fiber_new: "\e92a";
$i-playlist_play: "\e92b";
$i-music_video: "\e92c";
$i-playlist_add_check: "\e92d";
$i-queue_play_next: "\e92e";
$i-slow_motion_video: "\e92f";
$i-branding_watermark: "\e930";
$i-call_to_action: "\e931";
$i-featured_play_list: "\e932";
$i-featured_video: "\e933";
$i-video_call: "\e934";
$i-video_label: "\e935";
$i-missed_video_call: "\e936";
$i-control_camera: "\e937";
$i-dialer_sip: "\e938";
$i-email: "\e939";
$i-mail: "\e939";
$i-markunread: "\e939";
$i-local_post_office: "\e939";
$i-speaker_phone: "\e93a";
$i-present_to_all: "\e93b";
$i-cancel_presentation: "\e93c";
$i-pause_presentation: "\e93d";
$i-sentiment_satisfied_alt: "\e93e";
$i-content_copy: "\e93f";
$i-content_cut: "\e940";
$i-content_paste: "\e941";
$i-createmode_editedit: "\e942";
$i-drafts: "\e943";
$i-flagassistant_photo: "\e944";
$i-reply_all: "\e945";
$i-send: "\e946";
$i-next_week: "\e947";
$i-weekend: "\e948";
$i-access_time: "\e949";
$i-query_builder: "\e949";
$i-schedule: "\e949";
$i-bluetooth_searching: "\e94a";
$i-bluetooth_audio: "\e94a";
$i-brightness_medium: "\e94b";
$i-brightness_6: "\e94b";
$i-settings_system_daydream: "\e94c";
$i-insert_emoticon: "\e94d";
$i-tag_faces: "\e94d";
$i-mood: "\e94d";
$i-insert_photo: "\e94e";
$i-image: "\e94e";
$i-photo: "\e94e";
$i-keyboard_arrow_left: "\e94f";
$i-keyboard_arrow_right: "\e950";
$i-speaker: "\e951";
$i-speaker_group: "\e952";
$i-watch: "\e953";
$i-videogame_asset: "\e954";
$i-audiotrack: "\e955";
$i-brightness_2: "\e956";
$i-brightness_3: "\e957";
$i-brightness_4: "\e958";
$i-broken_image: "\e959";
$i-camera: "\e95a";
$i-camera_alt: "\e95b";
$i-photo_camera: "\e95b";
$i-local_see: "\e95b";
$i-camera_front: "\e95c";
$i-camera_rear: "\e95d";
$i-camera_roll: "\e95e";
$i-collectionsphoto_library: "\e95f";
$i-image_aspect_ratio: "\e960";
$i-monochrome_photos: "\e961";
$i-music_note: "\e962";
$i-navigate_before: "\e963";
$i-chevron_left: "\e963";
$i-navigate_next: "\e964";
$i-radio_button_unchecked: "\e965";
$i-photo_album: "\e966";
$i-picture_as_pdf: "\e967";
$i-rotate_left: "\e968";
$i-switch_camera: "\e969";
$i-switch_video: "\e96a";
$i-timelapse: "\e96b";
$i-timer_10: "\e96c";
$i-timer_3: "\e96d";
$i-timer: "\e96e";
$i-timer_off: "\e96f";
$i-wb_sunny: "\e970";
$i-photo_size_select_actual: "\e971";
$i-photo_size_select_large: "\e972";
$i-photo_size_select_small: "\e973";
$i-linked_camera: "\e974";
$i-add_a_photo: "\e975";
$i-movie_filter: "\e976";
$i-photo_filter: "\e977";
$i-add_photo_alternate: "\e978";
$i-image_search: "\e979";
$i-music_off: "\e97a";
$i-local_attractionlocal_play: "\e97b";
$i-local_moviestheaters: "\e97c";
$i-check: "\e97d";
$i-refresh: "\e97e";
$i-subdirectory_arrow_left: "\e97f";
$i-arrow_left: "\e980";
$i-arrow_right: "\e981";
$i-arrow_down: "\ea28";
$i-arrow_up: "\ea29";
$i-arrow_forward_ios: "\e982";
$i-drive_etatime_to_leave: "\e983";
$i-phone_bluetooth_speaker: "\e984";
$i-sim_card_alert: "\e985";
$i-sync_disabled: "\e986";
$i-sync_problem: "\e988";
$i-system_update: "\e989";
$i-tap_and_play: "\e98a";
$i-ondemand_video: "\e98b";
$i-personal_video: "\e98c";
$i-network_check: "\e98d";
$i-grouppeople: "\e98e";
$i-group_add: "\e98f";
$i-mood_bad: "\e990";
$i-notifications: "\e991";
$i-notifications_none: "\e992";
$i-notifications_off: "\e993";
$i-notifications_active: "\e994";
$i-notifications_paused: "\e995";
$i-sentiment_dissatisfied: "\e996";
$i-sentiment_neutral: "\e997";
$i-sentiment_satisfied: "\e998";
$i-sentiment_very_dissatisfied: "\e999";
$i-sentiment_very_satisfied: "\e99a";
$i-thumb_down_alt: "\e99b";
$i-thumb_up_alt: "\e99c";
$i-check_box: "\e99d";
$i-check_box_outline_blank: "\e99e";
$i-radio_button_checked: "\e99f";
$i-star: "\e9a0";
$i-star_half: "\e9a1";
$i-star_outline: "\e9a2";
$i-3d_rotation: "\e9a3";
$i-check_circle: "\e9a4";
$i-done: "\e9a5";
$i-done_all: "\e9a6";
$i-group_work: "\e9a7";
$i-historyrestore: "\e9a8";
$i-home: "\e9a9";
$i-hourglass_empty: "\e9aa";
$i-hourglass_full: "\e9ab";
$i-httpslock: "\e9ac";
$i-info: "\e9ad";
$i-info_outline: "\e9ae";
$i-label: "\e9af";
$i-label_outline: "\e9b0";
$i-launchopen_in_new: "\e9b1";
$i-lock_open: "\e9b2";
$i-lock_outline: "\e9b3";
$i-perm_camera_mic: "\e9b4";
$i-perm_contact_calendar: "\e9b5";
$i-perm_media: "\e9b6";
$i-picture_in_picture: "\e9b7";
$i-power_settings_new: "\e9b8";
$i-settings_input_svideo: "\e9b9";
$i-settings_power: "\e9ba";
$i-settings_remote: "\e9bb";
$i-settings_voice: "\e9bc";
$i-speaker_notes: "\e9bd";
$i-spellcheck: "\e9be";
$i-system_update_alt: "\e9bf";
$i-today: "\e9c0";
$i-view_week: "\e9c1";
$i-youtube_searched_for: "\e9c2";
$i-camera_enhance: "\e9c3";
$i-play_for_work: "\e9c4";
$i-indeterminate_check_box: "\e9c5";
$i-copyright: "\e9c6";
$i-picture_in_picture_alt: "\e9c7";
$i-date_range: "\e9c8";
$i-timeline: "\e9c9";
$i-update: "\e9ca";
$i-watch_later: "\e9cb";
$i-speaker_notes_off: "\e9cc";
$i-check_circle_outline: "\e9cd";
$i-done_outline: "\e9ce";
$i-maximize: "\e9cf";
$i-minimize: "\e9d0";
$i-calendar_today: "\e9d1";
$i-calendar_view_day: "\e9d2";
$i-label_important: "\e9d3";
$i-label_important_outline: "\e9d4";
$i-10k: "\e9d5";
$i-10mp: "\e9d6";
$i-11mp: "\e9d7";
$i-12mp: "\e9d8";
$i-13mp: "\e9d9";
$i-14mp: "\e9da";
$i-15mp: "\e9db";
$i-16mp: "\e9dc";
$i-17mp: "\e9dd";
$i-18mp: "\e9de";
$i-19mp: "\e9df";
$i-1k: "\e9e0";
$i-1k_plus: "\e9e1";
$i-20mp: "\e9e2";
$i-21mp: "\e9e3";
$i-22mp: "\e9e4";
$i-23mp: "\e9e5";
$i-24mp: "\e9e6";
$i-2k: "\e9e7";
$i-2k_plus: "\e9e8";
$i-2mp: "\e9e9";
$i-3k: "\e9ea";
$i-3k_plus: "\e9eb";
$i-3mp: "\e9ec";
$i-4k_plus: "\e9ed";
$i-4mp: "\e9ee";
$i-5k: "\e9ef";
$i-5k_plus: "\e9f0";
$i-5mp: "\e9f1";
$i-6k: "\e9f2";
$i-6k_plus: "\e9f3";
$i-6mp: "\e9f4";
$i-7k: "\e9f5";
$i-7k_plus: "\e9f6";
$i-7mp: "\e9f7";
$i-8k: "\e9f8";
$i-8k_plus: "\e9f9";
$i-8mp: "\e9fa";
$i-9k: "\e9fb";
$i-9k_plus: "\e9fc";
$i-9mp: "\e9fd";
$i-bus_alert: "\e9fe";
$i-circle_notifications: "\e9ff";
$i-file_download_done: "\ea00";
$i-imagesearch_roller: "\ea01";
$i-library_add_check: "\ea02";
$i-mp: "\ea03";
$i-panorama_photosphere: "\ea04";
$i-panorama_photosphere_select: "\ea05";
$i-railway_alert: "\ea06";
$i-remove_done: "\ea07";
$i-remove_moderator: "\ea08";
$i-repeat_on: "\ea09";
$i-repeat_one_on: "\ea0a";
$i-replay_circle_filled: "\ea0b";
$i-shield: "\ea0c";
$i-schedule_send: "\ea0d";
$i-file_present: "\ea0e";
$i-sync_alt: "\ea0f";
$i-emoji_emotions: "\ea10";
$i-emoji_events: "\ea11";
$i-emoji_objects: "\ea12";
$i-flip_camera_android: "\ea13";
$i-flip_camera_ios: "\ea14";
$i-cancel_schedule_send: "\ea15";
$i-nights_stay: "\ea16";
$i-takeout_dining: "\ea18";
$i-ac_unit: "\ea1a";
$i-all_inclusive: "\ea1b";
$i-business_center: "\ea1c";
$i-casino: "\ea1d";
$i-child_care: "\ea1e";
$i-child_friendly: "\ea1f";
$i-fitness_center: "\ea21";
$i-golf_course: "\ea23";
$i-kitchen: "\ea25";
$i-room_service: "\ea26";
$i-spa: "\ea27";
$i-keyboard_arrow_down: "\ea2a";
$i-keyboard_arrow_up: "\ea2b";

