.isi,
.isiContent,
.popupISI .contents {
  padding: 32px 0;
  font-size: 14px;
  line-height: em(10px, 8px);
  .isiHead {
    font-weight: 700;
    line-height: normal;
    color: map-get($theme, header-text);
    @media only screen and (max-width: 768px) {
      line-height: 24px;
    }
    .break {
      display: none;
      @media only screen and (max-width: 440px) and (min-width: 334px) {
        display: block;
      }
    }
    &.brhead {
      @media only screen and (max-width: 440px) {
        margin-bottom: 5px;
        line-height: 16px !important;
      }
    }
  }
  p {
    line-height: 1.2em;
    margin-bottom: 10px;
  }
  .link {
    color: map-get($theme, header-text);
  }
  ul {
    padding-left: 30px;
    li {
      padding-left: 8px;
    }
  }
  .break,
  .brAmneal,
  .brOn {
    display: none;
  }
  @media only screen and (max-width: 792px) and (min-width: 703px) {
    .break {
      display: block;
    }
  }
  @media only screen and (max-width: 497px) and (min-width: 390px) {
    .break,
    .brAmneal {
      display: block;
    }
  }
  @media only screen and (max-width: 422px) and (min-width: 328px) {
    .brOn {
      display: block;
    }
  }
}

.btnISI {
  right: 0;
  top: -3px;
  z-index: 1;
  font-size: 14px;
  min-width: 100px;
  border-radius: 0;
  padding: 6px 10px;
  position: absolute;
  @include font($fontB);
}

.fixedISI {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 50;
  height: 25%;
  height: 25vh;
  position: fixed;
  @extend %animateFast;
  background: map-get($theme, bg);
  color: map-get($theme, footer-text);
  border-top: map-get($theme, color) 3px solid;
  &.compact {
    height: 80px;
    min-height: 80px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 230px;
  }
  @media only screen and (max-width: 767px) {
    &,
    &.compact {
      height: 135px; // 95px
      min-height: 135px;
    }
  }
}
