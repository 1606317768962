@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?e809zr');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?e809zr#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?e809zr') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?e809zr') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?e809zr##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-add {
  &:before {
    content: $i-add; 
  }
}
.i-alternate_email {
  &:before {
    content: $i-alternate_email; 
  }
}
.i-fingerprint {
  &:before {
    content: $i-fingerprint; 
  }
}
.i-send_and_archive {
  &:before {
    content: $i-send_and_archive; 
  }
}
.i-attach_email {
  &:before {
    content: $i-attach_email; 
  }
}
.i-electric_bike {
  &:before {
    content: $i-electric_bike; 
  }
}
.i-electric_car {
  &:before {
    content: $i-electric_car; 
  }
}
.i-electric_moped {
  &:before {
    content: $i-electric_moped; 
  }
}
.i-electric_rickshaw {
  &:before {
    content: $i-electric_rickshaw; 
  }
}
.i-electric_scooter {
  &:before {
    content: $i-electric_scooter; 
  }
}
.i-electrical_services {
  &:before {
    content: $i-electrical_services; 
  }
}
.i-elevator {
  &:before {
    content: $i-elevator; 
  }
}
.i-handyman {
  &:before {
    content: $i-handyman; 
  }
}
.i-mark_email_read {
  &:before {
    content: $i-mark_email_read; 
  }
}
.i-mark_email_unread {
  &:before {
    content: $i-mark_email_unread; 
  }
}
.i-qr_code {
  &:before {
    content: $i-qr_code; 
  }
}
.i-qr_code_scanner {
  &:before {
    content: $i-qr_code_scanner; 
  }
}
.i-verified {
  &:before {
    content: $i-verified; 
  }
}
.i-wash {
  &:before {
    content: $i-wash; 
  }
}
.i-6_ft_apart {
  &:before {
    content: $i-6_ft_apart; 
  }
}
.i-clean_hands {
  &:before {
    content: $i-clean_hands; 
  }
}
.i-connect_without_contact {
  &:before {
    content: $i-connect_without_contact; 
  }
}
.i-coronavirus {
  &:before {
    content: $i-coronavirus; 
  }
}
.i-elderly {
  &:before {
    content: $i-elderly; 
  }
}
.i-follow_the_signs {
  &:before {
    content: $i-follow_the_signs; 
  }
}
.i-leave_bags_at_home {
  &:before {
    content: $i-leave_bags_at_home; 
  }
}
.i-masks {
  &:before {
    content: $i-masks; 
  }
}
.i-reduce_capacity {
  &:before {
    content: $i-reduce_capacity; 
  }
}
.i-sanitizer {
  &:before {
    content: $i-sanitizer; 
  }
}
.i-send_to_mobile {
  &:before {
    content: $i-send_to_mobile; 
  }
}
.i-sick {
  &:before {
    content: $i-sick; 
  }
}
.i-facebook {
  &:before {
    content: $i-facebook; 
  }
}
.i-align_horizontal_center {
  &:before {
    content: $i-align_horizontal_center; 
  }
}
.i-align_horizontal_left {
  &:before {
    content: $i-align_horizontal_left; 
  }
}
.i-align_horizontal_right {
  &:before {
    content: $i-align_horizontal_right; 
  }
}
.i-align_vertical_bottom {
  &:before {
    content: $i-align_vertical_bottom; 
  }
}
.i-align_vertical_center {
  &:before {
    content: $i-align_vertical_center; 
  }
}
.i-align_vertical_top {
  &:before {
    content: $i-align_vertical_top; 
  }
}
.i-horizontal_distribute {
  &:before {
    content: $i-horizontal_distribute; 
  }
}
.i-qr_code_2 {
  &:before {
    content: $i-qr_code_2; 
  }
}
.i-vertical_distribute {
  &:before {
    content: $i-vertical_distribute; 
  }
}
.i-download {
  &:before {
    content: $i-download; 
  }
}
.i-code-telemed {
  &:before {
    content: $i-code-telemed; 
  }
}
.i-covid-telemed {
  &:before {
    content: $i-covid-telemed; 
  }
}
.i-guide {
  &:before {
    content: $i-guide; 
  }
}
.i-hcp {
  &:before {
    content: $i-hcp; 
  }
}
.i-patient-telemed {
  &:before {
    content: $i-patient-telemed; 
  }
}
.i-close {
  &:before {
    content: $i-close; 
  }
}
.i-play {
  &:before {
    content: $i-play; 
  }
}
.i-light {
  &:before {
    content: $i-light; 
  }
}
.i-dark {
  &:before {
    content: $i-dark; 
  }
}
.i-error {
  &:before {
    content: $i-error; 
  }
}
.i-error_outline {
  &:before {
    content: $i-error_outline; 
  }
}
.i-warning {
  &:before {
    content: $i-warning; 
  }
}
.i-report_problem {
  &:before {
    content: $i-report_problem; 
  }
}
.i-add_alert {
  &:before {
    content: $i-add_alert; 
  }
}
.i-notification_important {
  &:before {
    content: $i-notification_important; 
  }
}
.i-av_timer {
  &:before {
    content: $i-av_timer; 
  }
}
.i-fast_forward {
  &:before {
    content: $i-fast_forward; 
  }
}
.i-loopsync {
  &:before {
    content: $i-loopsync; 
  }
}
.i-moviemovie_creation {
  &:before {
    content: $i-moviemovie_creation; 
  }
}
.i-library_add {
  &:before {
    content: $i-library_add; 
  }
}
.i-queue {
  &:before {
    content: $i-queue; 
  }
}
.i-add_to_photos {
  &:before {
    content: $i-add_to_photos; 
  }
}
.i-library_music {
  &:before {
    content: $i-library_music; 
  }
}
.i-pause {
  &:before {
    content: $i-pause; 
  }
}
.i-pause_circle_filled {
  &:before {
    content: $i-pause_circle_filled; 
  }
}
.i-pause_circle_outline {
  &:before {
    content: $i-pause_circle_outline; 
  }
}
.i-play_arrow {
  &:before {
    content: $i-play_arrow; 
  }
}
.i-play_circle_filled {
  &:before {
    content: $i-play_circle_filled; 
  }
}
.i-play_circle_outline {
  &:before {
    content: $i-play_circle_outline; 
  }
}
.i-playlist_add {
  &:before {
    content: $i-playlist_add; 
  }
}
.i-queue_music {
  &:before {
    content: $i-queue_music; 
  }
}
.i-radio {
  &:before {
    content: $i-radio; 
  }
}
.i-repeat {
  &:before {
    content: $i-repeat; 
  }
}
.i-repeat_one {
  &:before {
    content: $i-repeat_one; 
  }
}
.i-replay {
  &:before {
    content: $i-replay; 
  }
}
.i-skip_next {
  &:before {
    content: $i-skip_next; 
  }
}
.i-skip_previous {
  &:before {
    content: $i-skip_previous; 
  }
}
.i-surround_sound {
  &:before {
    content: $i-surround_sound; 
  }
}
.i-video_collection {
  &:before {
    content: $i-video_collection; 
  }
}
.i-videocam {
  &:before {
    content: $i-videocam; 
  }
}
.i-videocam_off {
  &:before {
    content: $i-videocam_off; 
  }
}
.i-airplay {
  &:before {
    content: $i-airplay; 
  }
}
.i-forward_10 {
  &:before {
    content: $i-forward_10; 
  }
}
.i-forward_30 {
  &:before {
    content: $i-forward_30; 
  }
}
.i-forward_5 {
  &:before {
    content: $i-forward_5; 
  }
}
.i-replay_10 {
  &:before {
    content: $i-replay_10; 
  }
}
.i-replay_30 {
  &:before {
    content: $i-replay_30; 
  }
}
.i-replay_5 {
  &:before {
    content: $i-replay_5; 
  }
}
.i-fiber_dvr {
  &:before {
    content: $i-fiber_dvr; 
  }
}
.i-fiber_new {
  &:before {
    content: $i-fiber_new; 
  }
}
.i-playlist_play {
  &:before {
    content: $i-playlist_play; 
  }
}
.i-music_video {
  &:before {
    content: $i-music_video; 
  }
}
.i-playlist_add_check {
  &:before {
    content: $i-playlist_add_check; 
  }
}
.i-queue_play_next {
  &:before {
    content: $i-queue_play_next; 
  }
}
.i-slow_motion_video {
  &:before {
    content: $i-slow_motion_video; 
  }
}
.i-branding_watermark {
  &:before {
    content: $i-branding_watermark; 
  }
}
.i-call_to_action {
  &:before {
    content: $i-call_to_action; 
  }
}
.i-featured_play_list {
  &:before {
    content: $i-featured_play_list; 
  }
}
.i-featured_video {
  &:before {
    content: $i-featured_video; 
  }
}
.i-video_call {
  &:before {
    content: $i-video_call; 
  }
}
.i-video_label {
  &:before {
    content: $i-video_label; 
  }
}
.i-missed_video_call {
  &:before {
    content: $i-missed_video_call; 
  }
}
.i-control_camera {
  &:before {
    content: $i-control_camera; 
  }
}
.i-dialer_sip {
  &:before {
    content: $i-dialer_sip; 
  }
}
.i-email {
  &:before {
    content: $i-email; 
  }
}
.i-mail {
  &:before {
    content: $i-mail; 
  }
}
.i-markunread {
  &:before {
    content: $i-markunread; 
  }
}
.i-local_post_office {
  &:before {
    content: $i-local_post_office; 
  }
}
.i-speaker_phone {
  &:before {
    content: $i-speaker_phone; 
  }
}
.i-present_to_all {
  &:before {
    content: $i-present_to_all; 
  }
}
.i-cancel_presentation {
  &:before {
    content: $i-cancel_presentation; 
  }
}
.i-pause_presentation {
  &:before {
    content: $i-pause_presentation; 
  }
}
.i-sentiment_satisfied_alt {
  &:before {
    content: $i-sentiment_satisfied_alt; 
  }
}
.i-content_copy {
  &:before {
    content: $i-content_copy; 
  }
}
.i-content_cut {
  &:before {
    content: $i-content_cut; 
  }
}
.i-content_paste {
  &:before {
    content: $i-content_paste; 
  }
}
.i-createmode_editedit {
  &:before {
    content: $i-createmode_editedit; 
  }
}
.i-drafts {
  &:before {
    content: $i-drafts; 
  }
}
.i-flagassistant_photo {
  &:before {
    content: $i-flagassistant_photo; 
  }
}
.i-reply_all {
  &:before {
    content: $i-reply_all; 
  }
}
.i-send {
  &:before {
    content: $i-send; 
  }
}
.i-next_week {
  &:before {
    content: $i-next_week; 
  }
}
.i-weekend {
  &:before {
    content: $i-weekend; 
  }
}
.i-access_time {
  &:before {
    content: $i-access_time; 
  }
}
.i-query_builder {
  &:before {
    content: $i-query_builder; 
  }
}
.i-schedule {
  &:before {
    content: $i-schedule; 
  }
}
.i-bluetooth_searching {
  &:before {
    content: $i-bluetooth_searching; 
  }
}
.i-bluetooth_audio {
  &:before {
    content: $i-bluetooth_audio; 
  }
}
.i-brightness_medium {
  &:before {
    content: $i-brightness_medium; 
  }
}
.i-brightness_6 {
  &:before {
    content: $i-brightness_6; 
  }
}
.i-settings_system_daydream {
  &:before {
    content: $i-settings_system_daydream; 
  }
}
.i-insert_emoticon {
  &:before {
    content: $i-insert_emoticon; 
  }
}
.i-tag_faces {
  &:before {
    content: $i-tag_faces; 
  }
}
.i-mood {
  &:before {
    content: $i-mood; 
  }
}
.i-insert_photo {
  &:before {
    content: $i-insert_photo; 
  }
}
.i-image {
  &:before {
    content: $i-image; 
  }
}
.i-photo {
  &:before {
    content: $i-photo; 
  }
}
.i-keyboard_arrow_left {
  &:before {
    content: $i-keyboard_arrow_left; 
  }
}
.i-keyboard_arrow_right {
  &:before {
    content: $i-keyboard_arrow_right; 
  }
}
.i-speaker {
  &:before {
    content: $i-speaker; 
  }
}
.i-speaker_group {
  &:before {
    content: $i-speaker_group; 
  }
}
.i-watch {
  &:before {
    content: $i-watch; 
  }
}
.i-videogame_asset {
  &:before {
    content: $i-videogame_asset; 
  }
}
.i-audiotrack {
  &:before {
    content: $i-audiotrack; 
  }
}
.i-brightness_2 {
  &:before {
    content: $i-brightness_2; 
  }
}
.i-brightness_3 {
  &:before {
    content: $i-brightness_3; 
  }
}
.i-brightness_4 {
  &:before {
    content: $i-brightness_4; 
  }
}
.i-broken_image {
  &:before {
    content: $i-broken_image; 
  }
}
.i-camera {
  &:before {
    content: $i-camera; 
  }
}
.i-camera_alt {
  &:before {
    content: $i-camera_alt; 
  }
}
.i-photo_camera {
  &:before {
    content: $i-photo_camera; 
  }
}
.i-local_see {
  &:before {
    content: $i-local_see; 
  }
}
.i-camera_front {
  &:before {
    content: $i-camera_front; 
  }
}
.i-camera_rear {
  &:before {
    content: $i-camera_rear; 
  }
}
.i-camera_roll {
  &:before {
    content: $i-camera_roll; 
  }
}
.i-collectionsphoto_library {
  &:before {
    content: $i-collectionsphoto_library; 
  }
}
.i-image_aspect_ratio {
  &:before {
    content: $i-image_aspect_ratio; 
  }
}
.i-monochrome_photos {
  &:before {
    content: $i-monochrome_photos; 
  }
}
.i-music_note {
  &:before {
    content: $i-music_note; 
  }
}
.i-navigate_before {
  &:before {
    content: $i-navigate_before; 
  }
}
.i-chevron_left {
  &:before {
    content: $i-chevron_left; 
  }
}
.i-navigate_next {
  &:before {
    content: $i-navigate_next; 
  }
}
.i-radio_button_unchecked {
  &:before {
    content: $i-radio_button_unchecked; 
  }
}
.i-photo_album {
  &:before {
    content: $i-photo_album; 
  }
}
.i-picture_as_pdf {
  &:before {
    content: $i-picture_as_pdf; 
  }
}
.i-rotate_left {
  &:before {
    content: $i-rotate_left; 
  }
}
.i-switch_camera {
  &:before {
    content: $i-switch_camera; 
  }
}
.i-switch_video {
  &:before {
    content: $i-switch_video; 
  }
}
.i-timelapse {
  &:before {
    content: $i-timelapse; 
  }
}
.i-timer_10 {
  &:before {
    content: $i-timer_10; 
  }
}
.i-timer_3 {
  &:before {
    content: $i-timer_3; 
  }
}
.i-timer {
  &:before {
    content: $i-timer; 
  }
}
.i-timer_off {
  &:before {
    content: $i-timer_off; 
  }
}
.i-wb_sunny {
  &:before {
    content: $i-wb_sunny; 
  }
}
.i-photo_size_select_actual {
  &:before {
    content: $i-photo_size_select_actual; 
  }
}
.i-photo_size_select_large {
  &:before {
    content: $i-photo_size_select_large; 
  }
}
.i-photo_size_select_small {
  &:before {
    content: $i-photo_size_select_small; 
  }
}
.i-linked_camera {
  &:before {
    content: $i-linked_camera; 
  }
}
.i-add_a_photo {
  &:before {
    content: $i-add_a_photo; 
  }
}
.i-movie_filter {
  &:before {
    content: $i-movie_filter; 
  }
}
.i-photo_filter {
  &:before {
    content: $i-photo_filter; 
  }
}
.i-add_photo_alternate {
  &:before {
    content: $i-add_photo_alternate; 
  }
}
.i-image_search {
  &:before {
    content: $i-image_search; 
  }
}
.i-music_off {
  &:before {
    content: $i-music_off; 
  }
}
.i-local_attractionlocal_play {
  &:before {
    content: $i-local_attractionlocal_play; 
  }
}
.i-local_moviestheaters {
  &:before {
    content: $i-local_moviestheaters; 
  }
}
.i-check {
  &:before {
    content: $i-check; 
  }
}
.i-refresh {
  &:before {
    content: $i-refresh; 
  }
}
.i-subdirectory_arrow_left {
  &:before {
    content: $i-subdirectory_arrow_left; 
  }
}
.i-arrow_left {
  &:before {
    content: $i-arrow_left; 
  }
}
.i-arrow_right {
  &:before {
    content: $i-arrow_right; 
  }
}
.i-arrow_down {
  &:before {
    content: $i-arrow_down; 
  }
}
.i-arrow_up {
  &:before {
    content: $i-arrow_up; 
  }
}
.i-arrow_forward_ios {
  &:before {
    content: $i-arrow_forward_ios; 
  }
}
.i-drive_etatime_to_leave {
  &:before {
    content: $i-drive_etatime_to_leave; 
  }
}
.i-phone_bluetooth_speaker {
  &:before {
    content: $i-phone_bluetooth_speaker; 
  }
}
.i-sim_card_alert {
  &:before {
    content: $i-sim_card_alert; 
  }
}
.i-sync_disabled {
  &:before {
    content: $i-sync_disabled; 
  }
}
.i-sync_problem {
  &:before {
    content: $i-sync_problem; 
  }
}
.i-system_update {
  &:before {
    content: $i-system_update; 
  }
}
.i-tap_and_play {
  &:before {
    content: $i-tap_and_play; 
  }
}
.i-ondemand_video {
  &:before {
    content: $i-ondemand_video; 
  }
}
.i-personal_video {
  &:before {
    content: $i-personal_video; 
  }
}
.i-network_check {
  &:before {
    content: $i-network_check; 
  }
}
.i-grouppeople {
  &:before {
    content: $i-grouppeople; 
  }
}
.i-group_add {
  &:before {
    content: $i-group_add; 
  }
}
.i-mood_bad {
  &:before {
    content: $i-mood_bad; 
  }
}
.i-notifications {
  &:before {
    content: $i-notifications; 
  }
}
.i-notifications_none {
  &:before {
    content: $i-notifications_none; 
  }
}
.i-notifications_off {
  &:before {
    content: $i-notifications_off; 
  }
}
.i-notifications_active {
  &:before {
    content: $i-notifications_active; 
  }
}
.i-notifications_paused {
  &:before {
    content: $i-notifications_paused; 
  }
}
.i-sentiment_dissatisfied {
  &:before {
    content: $i-sentiment_dissatisfied; 
  }
}
.i-sentiment_neutral {
  &:before {
    content: $i-sentiment_neutral; 
  }
}
.i-sentiment_satisfied {
  &:before {
    content: $i-sentiment_satisfied; 
  }
}
.i-sentiment_very_dissatisfied {
  &:before {
    content: $i-sentiment_very_dissatisfied; 
  }
}
.i-sentiment_very_satisfied {
  &:before {
    content: $i-sentiment_very_satisfied; 
  }
}
.i-thumb_down_alt {
  &:before {
    content: $i-thumb_down_alt; 
  }
}
.i-thumb_up_alt {
  &:before {
    content: $i-thumb_up_alt; 
  }
}
.i-check_box {
  &:before {
    content: $i-check_box; 
  }
}
.i-check_box_outline_blank {
  &:before {
    content: $i-check_box_outline_blank; 
  }
}
.i-radio_button_checked {
  &:before {
    content: $i-radio_button_checked; 
  }
}
.i-star {
  &:before {
    content: $i-star; 
  }
}
.i-star_half {
  &:before {
    content: $i-star_half; 
  }
}
.i-star_outline {
  &:before {
    content: $i-star_outline; 
  }
}
.i-3d_rotation {
  &:before {
    content: $i-3d_rotation; 
  }
}
.i-check_circle {
  &:before {
    content: $i-check_circle; 
  }
}
.i-done {
  &:before {
    content: $i-done; 
  }
}
.i-done_all {
  &:before {
    content: $i-done_all; 
  }
}
.i-group_work {
  &:before {
    content: $i-group_work; 
  }
}
.i-historyrestore {
  &:before {
    content: $i-historyrestore; 
  }
}
.i-home {
  &:before {
    content: $i-home; 
  }
}
.i-hourglass_empty {
  &:before {
    content: $i-hourglass_empty; 
  }
}
.i-hourglass_full {
  &:before {
    content: $i-hourglass_full; 
  }
}
.i-httpslock {
  &:before {
    content: $i-httpslock; 
  }
}
.i-info {
  &:before {
    content: $i-info; 
  }
}
.i-info_outline {
  &:before {
    content: $i-info_outline; 
  }
}
.i-label {
  &:before {
    content: $i-label; 
  }
}
.i-label_outline {
  &:before {
    content: $i-label_outline; 
  }
}
.i-launchopen_in_new {
  &:before {
    content: $i-launchopen_in_new; 
  }
}
.i-lock_open {
  &:before {
    content: $i-lock_open; 
  }
}
.i-lock_outline {
  &:before {
    content: $i-lock_outline; 
  }
}
.i-perm_camera_mic {
  &:before {
    content: $i-perm_camera_mic; 
  }
}
.i-perm_contact_calendar {
  &:before {
    content: $i-perm_contact_calendar; 
  }
}
.i-perm_media {
  &:before {
    content: $i-perm_media; 
  }
}
.i-picture_in_picture {
  &:before {
    content: $i-picture_in_picture; 
  }
}
.i-power_settings_new {
  &:before {
    content: $i-power_settings_new; 
  }
}
.i-settings_input_svideo {
  &:before {
    content: $i-settings_input_svideo; 
  }
}
.i-settings_power {
  &:before {
    content: $i-settings_power; 
  }
}
.i-settings_remote {
  &:before {
    content: $i-settings_remote; 
  }
}
.i-settings_voice {
  &:before {
    content: $i-settings_voice; 
  }
}
.i-speaker_notes {
  &:before {
    content: $i-speaker_notes; 
  }
}
.i-spellcheck {
  &:before {
    content: $i-spellcheck; 
  }
}
.i-system_update_alt {
  &:before {
    content: $i-system_update_alt; 
  }
}
.i-today {
  &:before {
    content: $i-today; 
  }
}
.i-view_week {
  &:before {
    content: $i-view_week; 
  }
}
.i-youtube_searched_for {
  &:before {
    content: $i-youtube_searched_for; 
  }
}
.i-camera_enhance {
  &:before {
    content: $i-camera_enhance; 
  }
}
.i-play_for_work {
  &:before {
    content: $i-play_for_work; 
  }
}
.i-indeterminate_check_box {
  &:before {
    content: $i-indeterminate_check_box; 
  }
}
.i-copyright {
  &:before {
    content: $i-copyright; 
  }
}
.i-picture_in_picture_alt {
  &:before {
    content: $i-picture_in_picture_alt; 
  }
}
.i-date_range {
  &:before {
    content: $i-date_range; 
  }
}
.i-timeline {
  &:before {
    content: $i-timeline; 
  }
}
.i-update {
  &:before {
    content: $i-update; 
  }
}
.i-watch_later {
  &:before {
    content: $i-watch_later; 
  }
}
.i-speaker_notes_off {
  &:before {
    content: $i-speaker_notes_off; 
  }
}
.i-check_circle_outline {
  &:before {
    content: $i-check_circle_outline; 
  }
}
.i-done_outline {
  &:before {
    content: $i-done_outline; 
  }
}
.i-maximize {
  &:before {
    content: $i-maximize; 
  }
}
.i-minimize {
  &:before {
    content: $i-minimize; 
  }
}
.i-calendar_today {
  &:before {
    content: $i-calendar_today; 
  }
}
.i-calendar_view_day {
  &:before {
    content: $i-calendar_view_day; 
  }
}
.i-label_important {
  &:before {
    content: $i-label_important; 
  }
}
.i-label_important_outline {
  &:before {
    content: $i-label_important_outline; 
  }
}
.i-10k {
  &:before {
    content: $i-10k; 
  }
}
.i-10mp {
  &:before {
    content: $i-10mp; 
  }
}
.i-11mp {
  &:before {
    content: $i-11mp; 
  }
}
.i-12mp {
  &:before {
    content: $i-12mp; 
  }
}
.i-13mp {
  &:before {
    content: $i-13mp; 
  }
}
.i-14mp {
  &:before {
    content: $i-14mp; 
  }
}
.i-15mp {
  &:before {
    content: $i-15mp; 
  }
}
.i-16mp {
  &:before {
    content: $i-16mp; 
  }
}
.i-17mp {
  &:before {
    content: $i-17mp; 
  }
}
.i-18mp {
  &:before {
    content: $i-18mp; 
  }
}
.i-19mp {
  &:before {
    content: $i-19mp; 
  }
}
.i-1k {
  &:before {
    content: $i-1k; 
  }
}
.i-1k_plus {
  &:before {
    content: $i-1k_plus; 
  }
}
.i-20mp {
  &:before {
    content: $i-20mp; 
  }
}
.i-21mp {
  &:before {
    content: $i-21mp; 
  }
}
.i-22mp {
  &:before {
    content: $i-22mp; 
  }
}
.i-23mp {
  &:before {
    content: $i-23mp; 
  }
}
.i-24mp {
  &:before {
    content: $i-24mp; 
  }
}
.i-2k {
  &:before {
    content: $i-2k; 
  }
}
.i-2k_plus {
  &:before {
    content: $i-2k_plus; 
  }
}
.i-2mp {
  &:before {
    content: $i-2mp; 
  }
}
.i-3k {
  &:before {
    content: $i-3k; 
  }
}
.i-3k_plus {
  &:before {
    content: $i-3k_plus; 
  }
}
.i-3mp {
  &:before {
    content: $i-3mp; 
  }
}
.i-4k_plus {
  &:before {
    content: $i-4k_plus; 
  }
}
.i-4mp {
  &:before {
    content: $i-4mp; 
  }
}
.i-5k {
  &:before {
    content: $i-5k; 
  }
}
.i-5k_plus {
  &:before {
    content: $i-5k_plus; 
  }
}
.i-5mp {
  &:before {
    content: $i-5mp; 
  }
}
.i-6k {
  &:before {
    content: $i-6k; 
  }
}
.i-6k_plus {
  &:before {
    content: $i-6k_plus; 
  }
}
.i-6mp {
  &:before {
    content: $i-6mp; 
  }
}
.i-7k {
  &:before {
    content: $i-7k; 
  }
}
.i-7k_plus {
  &:before {
    content: $i-7k_plus; 
  }
}
.i-7mp {
  &:before {
    content: $i-7mp; 
  }
}
.i-8k {
  &:before {
    content: $i-8k; 
  }
}
.i-8k_plus {
  &:before {
    content: $i-8k_plus; 
  }
}
.i-8mp {
  &:before {
    content: $i-8mp; 
  }
}
.i-9k {
  &:before {
    content: $i-9k; 
  }
}
.i-9k_plus {
  &:before {
    content: $i-9k_plus; 
  }
}
.i-9mp {
  &:before {
    content: $i-9mp; 
  }
}
.i-bus_alert {
  &:before {
    content: $i-bus_alert; 
  }
}
.i-circle_notifications {
  &:before {
    content: $i-circle_notifications; 
  }
}
.i-file_download_done {
  &:before {
    content: $i-file_download_done; 
  }
}
.i-imagesearch_roller {
  &:before {
    content: $i-imagesearch_roller; 
  }
}
.i-library_add_check {
  &:before {
    content: $i-library_add_check; 
  }
}
.i-mp {
  &:before {
    content: $i-mp; 
  }
}
.i-panorama_photosphere {
  &:before {
    content: $i-panorama_photosphere; 
  }
}
.i-panorama_photosphere_select {
  &:before {
    content: $i-panorama_photosphere_select; 
  }
}
.i-railway_alert {
  &:before {
    content: $i-railway_alert; 
  }
}
.i-remove_done {
  &:before {
    content: $i-remove_done; 
  }
}
.i-remove_moderator {
  &:before {
    content: $i-remove_moderator; 
  }
}
.i-repeat_on {
  &:before {
    content: $i-repeat_on; 
  }
}
.i-repeat_one_on {
  &:before {
    content: $i-repeat_one_on; 
  }
}
.i-replay_circle_filled {
  &:before {
    content: $i-replay_circle_filled; 
  }
}
.i-shield {
  &:before {
    content: $i-shield; 
  }
}
.i-schedule_send {
  &:before {
    content: $i-schedule_send; 
  }
}
.i-file_present {
  &:before {
    content: $i-file_present; 
  }
}
.i-sync_alt {
  &:before {
    content: $i-sync_alt; 
  }
}
.i-emoji_emotions {
  &:before {
    content: $i-emoji_emotions; 
  }
}
.i-emoji_events {
  &:before {
    content: $i-emoji_events; 
  }
}
.i-emoji_objects {
  &:before {
    content: $i-emoji_objects; 
  }
}
.i-flip_camera_android {
  &:before {
    content: $i-flip_camera_android; 
  }
}
.i-flip_camera_ios {
  &:before {
    content: $i-flip_camera_ios; 
  }
}
.i-cancel_schedule_send {
  &:before {
    content: $i-cancel_schedule_send; 
  }
}
.i-nights_stay {
  &:before {
    content: $i-nights_stay; 
  }
}
.i-takeout_dining {
  &:before {
    content: $i-takeout_dining; 
  }
}
.i-ac_unit {
  &:before {
    content: $i-ac_unit; 
  }
}
.i-all_inclusive {
  &:before {
    content: $i-all_inclusive; 
  }
}
.i-business_center {
  &:before {
    content: $i-business_center; 
  }
}
.i-casino {
  &:before {
    content: $i-casino; 
  }
}
.i-child_care {
  &:before {
    content: $i-child_care; 
  }
}
.i-child_friendly {
  &:before {
    content: $i-child_friendly; 
  }
}
.i-fitness_center {
  &:before {
    content: $i-fitness_center; 
  }
}
.i-golf_course {
  &:before {
    content: $i-golf_course; 
  }
}
.i-kitchen {
  &:before {
    content: $i-kitchen; 
  }
}
.i-room_service {
  &:before {
    content: $i-room_service; 
  }
}
.i-spa {
  &:before {
    content: $i-spa; 
  }
}
.i-keyboard_arrow_down {
  &:before {
    content: $i-keyboard_arrow_down; 
  }
}
.i-keyboard_arrow_up {
  &:before {
    content: $i-keyboard_arrow_up; 
  }
}

