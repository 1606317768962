/**!
 *	POPUP Plugin v4.1.4
 *	@author: Ajith S Punalur
 *  @date: 28-09-2021
 **/

$ColF: #fff;
$gutter: 40px;
$popBg: #fff;
$popTxt: #333;
$popBrdr: #ccc;
$headTxt: map-get($theme, color);

body.modalOpen {
  position: relative;
  overflow: hidden !important;

  .ui-datepicker {
    z-index: 1100 !important;
  }
}

.absFC {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  display: block;
  position: absolute;
}

.loading {
  z-index: 9999;
  background: rgba($ColF, 0.9);

  @extend .absFC !optional;
}

.modalOverlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  background: rgba(#000, 0);

  // display: none;
  // @extend %animateFast;
  &.none,
  &.transparent {
    background: none transparent !important;

    & > * {
      pointer-events: all;
    }

    & > .popup {
      @include box-shadow(0px 0px 30px rgba(#000, 0.8));
    }
  }

  &.none {
    pointer-events: none;

    * {
      pointer-events: all;
    }
  }

  &.active {
    display: block;
    visibility: visible;
    background: rgba(#000, 0.54);
  }

  &.inlineMock {
    position: absolute;
    .popup {
      position: relative;
      position: sticky;
    }
  }
}

.popup {
  $popHgt: 50%;
  $popWdth: 50%;
  z-index: 1000;
  display: none;
  color: $popTxt;
  width: $popWdth;
  height: $popHgt;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  top: $popHgt * 0.5;
  position: absolute;
  background: $popBg;

  &.open {
    display: block;
  }

  // &.floating{margin: 0;}
  & > .row {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }

  .popHeader,
  .popFooter,
  .popContent {
    width: 100%;
  }

  .popFooter,
  .popHeader {
    min-height: 40px;
    position: relative;
    padding: 10px 15px 0;

    @include clearfix;
  }

  .popFooter {
    background: #efefef;
  }

  .popHeader {
    color: $headTxt;
    font-size: 16px;
    border-bottom: map-get($theme, brdr) 1px solid;

    &.lsBtn {
      padding-left: $gutter;
    }

    &.rsBtn {
      padding-right: $gutter;
    }

    &.xsBtn {
      padding-left: $gutter;
      padding-right: $gutter;
    }

    .close {
      top: 0px;
      right: 0px;
      padding: 1px;
      color: #fff;
      display: block;
      width: $gutter;
      height: $gutter;
      cursor: pointer;
      font-size: 30px;
      min-width: $gutter;
      text-align: center;
      position: absolute;
      font-weight: normal;
      text-decoration: none;
      line-height: $gutter - 4;
      border: none transparent;
      background: map-get($theme, color);

      @include opacity(1);
    }

    .btn.back {
      top: 0;
      left: 0;
      width: $gutter;
      height: $gutter;
      font-size: 1.7em;
      position: absolute;

      &:hover,
      &:focus,
      &:active {
        background: rgba(#000, 0.12);
      }
    }

    & > .L:first-child {
      margin-left: -10px;
    }

    & > .L,
    & > .R {
      & > .btn {
        margin-top: -6px;
      }
    }
  }

  .popFooter {
    padding: 5px 15px;
    border-top: map-get($theme, brdr) 1px solid;
  }

  .popContent {
    // height: 100%;
    padding: 10px;
    overflow-y: scroll;
    overflow-y: auto;

    .fWHWrapper,
    .iframe {
      top: 0px;
      left: 0px;
      z-index: 1;
      right: 0px;
      bottom: 0px;
      position: absolute;
      width: 100% !important;
      height: 100% !important;
    }
  }

  iframe.popContent {
    width: 100%;
  }

  &[data-xsource="true"] {
    .popContent {
      position: relative;

      iframe {
        @extend .fWHWrapper !optional;
      }
    }
  }

  &[data-loader],
  &[data-loader="true"] {
    .popContent {
      overflow: hidden;
      position: relative;
      &:after,
      &:before {
        pointer-events: all;
      }
      &:before {
        background-color: rgba($ColF, 0.8);

        @extend .loading !optional;
      }
      &:after {
        top: 50%;
        left: 50%;
        content: "";
        z-index: 10000;
        position: absolute;
        @extend .loader;
      }
    }
  }

  &[data-loader=""],
  &[data-loader="false"] {
    .popContent {
      overflow: auto;
      &:after,
      &:before {
        display: none;
      }
    }
  }

  // animations
  // &.open{
  // }
  &.bounceIn,
  &.bounceInDown,
  &.bounceInLeft,
  &.bounceInRight,
  &.bounceInUp,
  &.fadeIn,
  &.fadeInDown,
  &.fadeInDownBig,
  &.fadeInLeft,
  &.fadeInLeftBig,
  &.fadeInRight,
  &.fadeInRightBig,
  &.fadeInUp,
  &.fadeInUpBig,
  &.flip,
  &.flipInX,
  &.flipInY,
  &.lightSpeedIn,
  &.rotateIn,
  &.rotateInDownLeft,
  &.rotateInDownRight,
  &.rotateInUpLeft,
  &.rotateInUpRight,
  &.slideInUp,
  &.slideInDown,
  &.slideInLeft,
  &.slideInRight,
  &.zoomIn,
  &.zoomInDown,
  &.zoomInLeft,
  &.zoomInRight,
  &.zoomInUp,
  &.hinge,
  &.jackInTheBox,
  &.rollIn,
  &.bounceOut,
  &.bounceOutDown,
  &.bounceOutLeft,
  &.bounceOutRight,
  &.bounceOutUp,
  &.fadeOut,
  &.fadeOutDown,
  &.fadeOutDownBig,
  &.fadeOutLeft,
  &.fadeOutLeftBig,
  &.fadeOutRight,
  &.fadeOutRightBig,
  &.fadeOutUp,
  &.fadeOutUpBig,
  &.flipOutX,
  &.flipOutY,
  &.lightSpeedOut,
  &.rotateOut,
  &.rotateOutDownLeft,
  &.rotateOutDownRight,
  &.rotateOutUpLeft,
  &.rotateOutUpRight,
  &.slideOutUp,
  &.slideOutDown,
  &.slideOutLeft,
  &.slideOutRight,
  &.zoomOut,
  &.zoomOutDown,
  &.zoomOutLeft,
  &.zoomOutRight,
  &.zoomOutUp,
  &.rollOut,
  &.bounce,
  &.flash,
  &.pulse,
  &.rubberBand,
  &.shake,
  &.swing,
  &.tada,
  &.wobble,
  &.jello {
    -webkit-animation-duration: 0.84s;
    animation-duration: 0.84s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  &.rotateXIn {
    @include animation("rotateXIn 500ms 1 10ms");
  }

  &.rotateXOut {
    @include animation("rotateXOut 500ms 1 10ms");
  }

  &.rotateYIn {
    @include animation("rotateYIn 500ms 1 10ms");
  }

  &.rotateYOut {
    @include animation("rotateYOut 500ms 1 10ms");
  }

  &.asymmetricScaleIn,
  &.asymmetricScaleOut {
    &,
    .popContent {
      overflow: hidden !important;
    }
  }

  &.asymmetricScaleIn {
    @include animation("asymmetricScaleIn 2s 1 10ms");
  }

  &.asymmetricScaleOut {
    @include animation("asymmetricScaleOut 500ms 1 0ms");
  }
}
