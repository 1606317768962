.banner {
  display: flex;
  max-height: 25vw;
  min-height: 333px;
  position: relative;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: map-get($color, light);
  background-position: right top;
  // &:after,
  &:before {
    top: 0;
    left: 0;
    width: 40%; // 83%
    opacity: 1;
    height: 100%;
    content: " ";
    position: absolute;
  }
  &:before {
    @include linear-gradient(
      to right,
      rgba(nth(map-deep-get($theme, bannerGrad, 0), 1), 1) nth(map-deep-get($theme, bannerGrad, 0), 2),
      rgba(nth(map-deep-get($theme, bannerGrad, 1), 1), 1) nth(map-deep-get($theme, bannerGrad, 1), 2),
      rgba(nth(map-deep-get($theme, bannerGrad, 2), 1), 0.41) nth(map-deep-get($theme, bannerGrad, 2), 2),
      rgba(nth(map-deep-get($theme, bannerGrad, 3), 1), 0) nth(map-deep-get($theme, bannerGrad, 3), 2)
    );
  }
  &.home {
    background-image: url("#{$img}/banner/home.png");
    // &:after
    &:before {
      display: none;
    }
    @media only screen and (max-width: 576px) {
      min-height: calc(100vh - 25vh);
    }
    // &:before {
    //   width: 50%; // 60%
    //   @include linear-gradient(
    //     to right,
    //     rgba(nth(map-deep-get($theme, bannerGrad, 0), 1), 0.4) nth(map-deep-get($theme, bannerGrad, 0), 2), // 0.5
    //     rgba(nth(map-deep-get($theme, bannerGrad, 1), 1), 0.4) nth(map-deep-get($theme, bannerGrad, 1), 2), // 0.5
    //     rgba(nth(map-deep-get($theme, bannerGrad, 2), 1), 0.3) nth(map-deep-get($theme, bannerGrad, 2), 2), // 0.41
    //     rgba(nth(map-deep-get($theme, bannerGrad, 3), 1), 0) nth(map-deep-get($theme, bannerGrad, 3), 2)
    //   );
    // }
    // &:after {
    //   width: 60%;
    //   z-index: 1;
    //   opacity: 0.5;
    //   @include linear-gradient(
    //     to right,
    //     rgba(map-get($color, dark), 1) 0%,
    //     rgba(map-get($color, dark), 1) 73%,
    //     rgba(map-get($color, dark), 1) 76%,
    //     rgba(map-get($color, dark), 0) 100%
    //   );
    // }
  }
  &.dosing {
    background-image: url("#{$img}/banner/dosing-rytary.png");
  }
  &.educating {
    background-image: url("#{$img}/banner/educating-patient.png");
  }
  &.educate {
    background-image: url("#{$img}/banner/educating-digital.png");
    @media only screen and (max-width: 576px) {
      min-height: calc(100vh - 25vh);
    }
  }
  &.exploring {
    background-image: url("#{$img}/banner/exploring-rytary.png");
  }
  & > .container-fluid {
    z-index: 4;
  }
  .caption {
    width: 100%;
    padding: 50px 0;
    max-width: 480px;
    text-align: left;
    text-shadow: 0 2px 4px rgba(map-get($color, dark), 0.5);
    .link {
      margin: 10px 0;
      display: block;
      text-decoration: underline;
      color: map-get($color, light);
    }
  }
  .title {
    line-height: 1.1em;
    margin-bottom: 24px;
  }
  .description {
    font-size: 24px;
    line-height: 1.5em;
    @include font($fontL);
  }
  .btn {
    border: rgba(map-get($color, light), 0.7) 1px solid;
  }
  .btnWatchVideo {
    margin-top: 20px;
  }

  @media only screen and (max-width: 576px) {
    max-height: 100vh;
    align-items: flex-end;
    // min-height: calc(100vh - 25vh); // -126px -178px
    background-position: center top;
    &:before {
      top: auto;
      bottom: 0;
      width: 100%;
      height: 80%;
      @include linear-gradient(to top, rgba(map-get($theme, color), 0.7), rgba(map-get($color, dark), 0));
    }
    & > .container-fluid {
      bottom: 0;
      position: absolute;
    }
    .caption {
      width: 100%;
      padding: 30px 0;
      // padding: 0 9px;
      // margin-bottom: 45px;
    }
    .title {
      font-size: 32px;
      margin-bottom: 15px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
    }
    .btn {
      height: 50px;
      padding: 10px;
      font-size: 18px;
      min-width: 211px;
      // border: rgba(map-get($color, light), 0.7) 1px solid;
    }

    &.home {
      background-image: url("#{$img}/banner/home_mob.png");
      &:before {
        width: 100%;
        height: 80%;
        @include linear-gradient(to top, rgba(map-get($theme, color), 0.7), rgba(map-get($color, dark), 0));
      }
    }
    &.dosing {
      background-image: url("#{$img}/banner/dosing-rytary_mob.png");
    }
    &.educating {
      background-image: url("#{$img}/banner/educating-patient_mob.png");
    }
    &.educate {
      background-image: url("#{$img}/banner/educating-digital_mob.png");
    }
    &.exploring {
      background-image: url("#{$img}/banner/exploring-rytary_mob.png");
    }
  }
}
