.exploreSection {
  padding: 60px 0 10px;
  .title {
    margin-bottom: 25px;
    @include font($fontL);
    // color: map-get($theme, color);
    color: map-get($theme, header-text);
    @media only screen and (max-width: 767px) {
      &.h2 {
        font-size: 24px;
      }
    }
  }
  .container-fluid {
    position: relative;
  }
  // &.caseStudies {
  // }
  // &.pharmacokinetics {
  // }
  @media only screen and (max-width: 767px) {
    padding-top: 32px;
    .title {
      margin-bottom: 10px;
    }
  }
}
