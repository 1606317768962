%videoGrid {
  margin-bottom: 45px;
  .videoPlayer {
    width: 100%;
    text-align: center;
  }
  a {
    height: 100%;
    display: block;
    padding: 10px 15px;
    border-radius: 4px;
    @extend %animateFast;
    &:hover,
    &:focus,
    &:active {
      background: rgba(map-get($theme, accent), 0.08);
      .videoPlayer {
        &:before {
          transform: scale(1.2);
        }
      }
    }
  }
  .caption {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 15px;
    @include font($fontB);
    color: map-get($theme, text);
    cite {
      font-size: 17px;
      font-style: italic;
      white-space: nowrap;
      @include font($fontM);
      color: map-get($theme, accent);
    }
  }
  .description {
    font-size: 18px;
    line-height: 26px;
    @include font($fontL);
    color: map-get($theme, text);
  }
  @media only screen and (max-width: 767px) {
    .description {
      font-size: 16px;
      line-height: 20px;
    }
    .caption {
      font-size: 16px;
      line-height: 18px;
      cite {
        font-size: 14px;
        // white-space: nowrap;
      }
    }
  }
  @media only screen and (max-width: 567px) {
    margin-bottom: 24px;
  }
}

.videoGrid,
.videoList {
  @include notaList;
  li {
    @extend %videoGrid;
  }
}

.videoGrid {
  @include make-row();
  li {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
    padding: 0;
  }
  @media only screen and (max-width: 567px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: y mandatory;
    li {
      white-space: normal;
      vertical-align: top;
      display: inline-block;
      width: calc(100% - 60px);
      scroll-snap-align: start;
      &:last-child {
        margin-right: 60px;
      }
      &:first-child {
        display: none;
      }
    }
  }
}

.videoList {
  li {
    margin: 3px 0;
    display: flex;
    padding: 10px 0;
    & + li {
      border-top: 1px solid rgba(map-get($color, dark), 0.16);
    }
  }
  a {
    width: 280px;
  }
  .dtls {
    width: 100%;
  }
  .videoPlayer {
    margin: 0;
  }
}

@media only screen and (min-width: 568px) {
  .primeVideo,
  .nav-dots {
    display: none;
  }
}

.primeVideo {
  @extend %videoGrid;
}

.nav-dots {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  text-align: center;
  a {
    margin: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #979797;
    @extend %animateFast;
    &:focus {
      border-color: rgba(map-get($theme, color), 0.5);
    }
    &:hover,
    &:active {
      border-color: map-get($theme, color);
      background: rgba(map-get($theme, color), 0.5);
    }
    &.active {
      background: map-get($theme, color);
      border-color: map-get($theme, color);
    }
  }
}
