@import "theme";
@import "bsVars";

$baseFontSize: map-get($theme, baseFontSize);
$base-font-size: $baseFontSize;

$Txt: map-get($theme, text);

$prColor: map-get($theme, color);

$prLink: map-get($theme, link);
$prFont: map-get($theme, font);

$Brdr: map-get($theme, brdr);

$colorType: $color;

$brdRad: 4px;

$bgColor: "default" #fff $Txt, "primary" map-get($color, primary) #fff, "success" map-get($color, success) #fff,
  "info" map-get($color, info) #fff, "warning" map-get($color, warning) #fff, "danger" map-get($color, danger) #fff,
  "theme" $prColor #fff, "accent" map-get($theme, accent) #fff;

// HD: 1920 X 1080
// $minWidth: 1080px;
// 4k: 3840 X 2160
// $maxWidth: 3840px;

$img: "../images";
$vid: "../videos";
$fonts: "../fonts";

$owl-image-path: "#{$img}/";

$icomoon-font-path: "#{$fonts}/icon";
$fi-font-path: "#{$fonts}/icon";
