body {
  &.onLoading {
    &:after,
    &:before {
      content: "";
      position: fixed;
    }
    &:before {
      z-index: 9999;
      pointer-events: all;
      background-color: rgba(#ccc, 0.8) !important;
      @extend .absFC;
    }
    &:after {
      top: 50%;
      left: 50%;
      z-index: 10000;
      @extend .loader;
      border-top-color: map-get($theme, accent);
      margin: remc(60px * 0.5) 0 0 remc(-60px * 0.5);
      border-color: rgba(map-get($color, dark), 0.3);
    }
    &[data-loader-message]:after {
      left: 0;
      right: 0;
      top: 50%;
      z-index: 10001;
      margin: 0 auto;
      display: block;
      color: $prColor;
      position: absolute;
      text-align: center;
      margin-top: remc(25px);
      background: map-get($color, light);
      content: attr(data-loader-message);
    }
  }
}

main {
  position: relative;
}

.sectBg {
  background: url("#{$img}/bg.png") left center/cover no-repeat;
}

.bgGray {
  padding: 60px 0 45px;
  background: map-get($theme, gray);
}
.reference {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
}
.mBottom {
  margin-bottom: 40px;
}

.popup {
  &.popupStyle {
    .popHeader,
    .popFooter {
      border: none;
      background: transparent;
    }
    .popHeader {
      height: 70px;
      padding-top: 0;

      .close {
        top: auto;
        bottom: 0;
        right: 40px;
        width: 36px;
        height: 36px;
        font-size: 28px;
        min-width: auto;
        background: none;
        border-radius: 50%;
        @extend %animateFast;
        color: map-get($color, primary);
        &:hover,
        &:active {
          background: rgba(map-get($color, primary), 0.16);
        }
        @media only screen and (max-width: 480px) {
          bottom: 10px;
        }
      }
    }
    .popContent {
      padding: 34px 100px;
      @include scrollbar(8px, map-get($theme, color), #878787);
      .form {
        margin-bottom: 85px;
        &-group {
          label[for] {
            @include font($fontM);
            color: map-get($color, primary);
          }
        }
        .action {
          margin-top: 50px;
          text-align: center;
        }
      }
      @include media-breakpoint-down(md) {
        padding: 15px 20px;
      }
    }
    .popFooter {
      height: 75px;
    }
  }
  &.popupReqRep {
    right: 0;
    margin: 0 auto;
    max-width: 1078px;
    left: 0 !important;
    .popHeader {
      height: 110px;
      padding-bottom: 34px;
      @media only screen and (max-width: 992px) {
        height: 68px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .close {
        bottom: 34px;
        @media only screen and (max-width: 992px) {
          right: 10px;
          bottom: 15px;
        }
      }
    }
    .popContent {
      width: calc(100% - 20px);
      @media only screen and (min-width: 992px) {
        width: calc(100% - 52px);
        padding: 0 50px 34px 100px;
      }
      p {
        font-size: 20px;
        margin-bottom: 25px;
      }
      @include media-breakpoint-down(md) {
        padding: 0px 16px 15px;
      }
      .form {
        margin-bottom: 0;
        .check {
          margin: 15px 0;
          @media only screen and (max-width: 767px) {
            &:first-child {
              margin-top: 5px;
            }
          }
        }
      }
    }
    .popFooter {
      height: 85px;
      @include media-breakpoint-down(md) {
        height: 36px;
      }
    }
  }
  &.popupVideo {
    overflow: visible;
    background: transparent;
    .popHeader {
      height: 0;
      padding: 0;
      z-index: 2;
      min-height: 0;
      overflow: visible;
      position: relative;
      border: 0 none transparent;
      .close {
        top: auto;
        bottom: 0;
        right: 3px;
        padding: 3px;
        line-height: 1;
        width: remc(36px);
        height: remc(36px);
        position: absolute;
        min-width: remc(36px);
        font-size: remc(24px);
        // @media only screen and (min-width: 768px) {
        //   background: transparent;
        // }
      }
      @media only screen and (max-height: 640px) {
        min-height: 0;
        .close {
          right: 5px;
          transform: translateY(100%);
          background: rgba(map-get($theme, color), 0.7);
        }
      }
    }
    .popContent {
      background: map-get($color, dark);
    }
  }
  &.popupLeaving {
    border-radius: 6px;

    .popContent {
      display: flex;
      padding: 0 30px;
      text-align: center;
      align-items: center;
      p {
        font-size: 24px;
        color: #3e3e3e;
        @include font($fontM);
        @media only screen and (max-width: 480px) {
          font-size: 18px;
        }
      }
      .wrap {
        margin: 0 auto;
        max-width: 576px;
      }
    }
    .popFooter {
      padding: 0;
      height: auto;
      text-transform: uppercase;
      .btn {
        margin-bottom: 48px;
        @media only screen and (max-width: 480px) {
          margin: 24px 0;
        }
      }
    }
  }
  // &.popupInform {
  //   .popContent {
  //     p {
  //       color: #3e3e3e;
  //       font-size: 24px;
  //       text-align: center;
  //       padding: 15px 25px;
  //       @include font($fontM);
  //     }
  //   }
  // }
}

// .page {
//   // padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
//   // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
//   top: 0;
//   left: 0;
//   z-index: 10;
//   width: 100%;
//   max-height: 100vh;
//   position: absolute;
//   min-height: remc(1920px);
//   background: map-get($theme, bg); // height: 100vh;
//   // overflow: scroll; // /* has to be scroll, not auto
//   // overflow-x: hidden;
//   // overflow-y: scroll;
//   // -webkit-overflow-scrolling: touch;
//   // @extend %animateFast;
// }
