.heading {
  @include font($fontL);
  color: map-get($color, primary);
  margin-bottom: 10px;
  &.header-text {
    color: map-get($theme, header-text);
  }
  @media only screen and (max-width: 991px) {
    margin-top: 24px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
}
.notes {
  font-size: 20px;
  @include font($fontL);
  color: map-get($theme, text);
  line-height: 30px;
  margin-bottom: 30px;
  &.count {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;
    @media only screen and (max-width: 767px) {
      padding-bottom: 15px;
    }
  }
}
.w-btn {
  max-width: 184px;
  width: 100%;
  @media only screen and (max-width: 1200px) {
    display: block;
    margin: 0 auto;
  }
  @media only screen and (max-width: 567px) {
    max-width: 100%;
  }
}
.btnT {
  max-width: 271px;
  line-height: 1em;
  align-items: center;
  display: inline-flex;
  .i {
    margin-right: 5px;
  }
  @media only screen and (max-width: 1200px) {
    display: block;
    margin: 0 auto;
  }
  @media only screen and (max-width: 567px) {
    max-width: 100%;
  }
}
.counting {
  width: 110px;
  height: 107px;
  display: block;
  padding: 25px 0;
  font-size: 49px;
  text-align: center;
  border-radius: 50%;
  @include font($fontB);
  margin: 30px auto 10px;
  color: map-get($theme, write);
  background-color: map-get($color, primary);
  small {
    font-size: 27px;
  }
  &.size {
    padding: 40px 0;
    font-size: 55px;
  }
  // @media only screen and (max-width: 767px) {
  //   margin-top: 25px;
  // }
}
.download {
  .i {
    font-size: 24px;
  }
}
.footNote {
  font-size: 14px;
  line-height: 16px;
  @include font($fontL);
  color: map-get($theme, text);
  p {
    margin-bottom: 10px;
  }
  .ref {
    &[aria-symbol] {
      padding-left: 6px;
      position: relative;
      &:before {
        left: 0;
        top: -3px;
        font-size: 10px;
        position: absolute;
        content: attr(aria-symbol);
      }
    }
    &[aria-symbol="*"] {
      &:before {
        top: -1px;
        font-size: 10px;
      }
    }
  }
}
.border {
  width: 100%;
  height: 22px;
  background-color: map-get($color, primary);
  // @media only screen and (max-width: 991px) {
  //   display: none;
  // }
}
.list {
  margin-top: 55px;
  li {
    font-size: 20px;
    margin-bottom: 35px;
    @include font($fontL);
    color: map-get($theme, text);
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 0;
    li {
      margin-bottom: 10px;
    }
  }
}
.capsule {
  font-size: 20px;
  text-align: center;
  @include font($fontB);
  color: map-get($theme, text);
  &.brdr {
    padding: 25px 0;
    border-right: 2px solid #d8d8d8;
  }
  hr {
    margin-left: 80px;
    margin-right: 90px;
    border: 2px solid #d8d8d8;
    @media only screen and (max-width: 567px) {
      margin-left: 55px;
      margin-right: 55px;
    }
    @media only screen and (max-width: 440px) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  span {
    @include font($fontL);
  }
  .tid {
    color: map-get($theme, colorL);
    @include font($fontB);
  }
  @media only screen and (max-width: 390px) {
    font-size: 15px;
  }
}

.dosing {
  .hgLgtCards {
    // padding: 12px 0;
    @media only screen and (max-width: 991px) {
      &.ptop {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
