nav.filter {
  position: relative;
  background: map-get($color, light);
  box-shadow: 0 3px 3px 0 rgba(map-get($color, dark), 0.1);
  ul.menu {
    display: flex;
    justify-content: space-between;
    @include notaList;
    li {
      display: inline-block;
      @include font($fontM);
      a {
        display: block;
        font-size: 24px;
        padding: 7px 4px;
        min-width: 100px;
        line-height: 58px;
        position: relative;
        text-align: center;
        color: map-get($theme, color);
        &:before {
          left: 0;
          right: 0;
          bottom: 0;
          width: 0%;
          height: 10px;
          opacity: 0.7;
          content: " ";
          margin: 0 auto;
          position: absolute;
          @extend %animateFast;
          border-radius: 5.5px 5.5px 0 0;
          background: map-get($theme, color);
        }
        &:hover,
        &:focus,
        &:active {
          color: map-get($theme, color);
          &:before {
            width: 100%;
          }
        }
        // &.active {
        //   &:before {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  .mobView {
    display: none;
    padding: 12px 0;
    font-style: 20px;
    align-items: stretch;
    & > span {
      line-height: 46px;
      padding-right: 20px;
      border-right: 1px solid map-get($theme, brdr);
    }
    .SumoSelect {
      width: 100%;
      @media only screen and (max-width: 359px) {
        width: 82%;
      }
      & > .CaptionCont {
        line-height: 38px;
        padding: 4px 4px 4px 20px;
        border-color: transparent;
        & > label {
          width: 30px;
          & > i {
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: middle;
            background: transparent;
            &:before {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              line-height: 1;
              font-size: 24px;
              text-indent: -4px;
              position: absolute;
              text-align: center;
              content: $i-arrow_down;
              color: map-get($theme, colorXL);
              font-family: "#{$icomoon-font-family}";
            }
          }
        }
      }
      & > .optWrapper {
        & > .options {
          padding: 3px 18px;
          li.opt {
            padding: 17px 6px;
            @extend %animateFast;
            & + li.opt {
              border-top: 2px solid map-get($theme, brdr);
            }
            &:hover,
            &:focus,
            &:active {
              background: rgba(map-get($theme, color), 0.16);
              label {
                @include font($fontB);
                color: map-get($theme, color);
              }
            }
            // &.selected {
            //   label {
            //     @include font($fontB);
            //     color: map-get($theme, color);
            //   }
            // }
          }
        }
      }
      &.open {
        & > .optWrapper {
          font-size: 18px;
          line-height: 27px;
          border-radius: 6px;
          top: calc(100% + 18px);
        }
      }
    }
  }

  .ink {
    bottom: 0;
    height: 10px;
    position: absolute;
    transition: all 0.3s ease-in;
    border-radius: 5.5px 5.5px 0 0;
    background: map-get($theme, color);
  }

  @media only screen and (max-width: 991px) {
    box-shadow: 0 2px 4px rgba(map-get($color, dark), 0.5);
    ul.menu {
      display: none;
    }
    .mobView {
      display: flex;
      font-size: 20px;
      padding: 11px 0;
    }
  }
}
