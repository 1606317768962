$baseFontSize: 18px;
$baseLineHgt: 1.4em;

$fontFamily: "Neue Helvetica W05", sans-serif;
// $scFontFamily: "Lato", sans-serif;

// COMMON FONT CHART
// -----------------
$fontUL: 100; // 'fontName ultralight', sans-serif;
$fontT: 100; // 'fontName thin', sans-serif;
$fontL: 300; // 'fontName light', sans-serif;
$font: 400; // 'fontName regular', sans-serif;
$fontM: 500; // 'fontName medium', sans-serif;
$fontSB: 500; // 'fontName semibold', sans-serif;
$fontB: 700; // 'fontName bold', sans-serif;
$fontH: 900; // 'fontName heavy', sans-serif;
$fontBK: 900; // 'fontName black', sans-serif;

$theme: (
  fontFamily: $fontFamily,
  baseLineHgt: $baseLineHgt,
  baseFontSize: $baseFontSize,
  font: $font,
  fontL: $fontL,
  fontT: $fontT,
  fontM: $fontM,
  fontB: $fontB,
  fontH: $fontH,
  fontUL: $fontUL,
  fontSB: $fontSB,
  fontBK: $fontBK,
  text: #2d2d2d,
  textL: #424242,
  textD: #4d4d4d,
  bg: #fff,
  matBg: #edeef0,
  write: #fff,
  color: #410099,
  colorL: #7b61c3,
  colorXL: #bc83de,
  accent: #00a8e0,
  link: #410099,
  brdr: #ccc,
  gray: #e6dfef,
  grayD: #5f5f5f,
  header-bg: #fff,
  header-tp: #e6dfee,
  header-text: #452d8c,
  header-link: #410099,
  footer-bg: #eaeaea,
  footer-text: #4b4b4b,
  footer-link: #410099,
  cardHeadGrad: (
    0: #7600c9,
    1: #410099,
  ),
  bannerGrad: (
    0: #170057 0%,
    1: #170057 29%,
    2: #170057 56%,
    3: #170057 100%,
  ),
  types: (
    primary: #410099,
    success: #7cbf7f,
    info: #006ed0,
    warning: #ffa200,
    danger: #db0000,
    accent: #00a7e0,
    light: #fff,
    dark: #000,
  ),
  contrast: (
    primary: #fff,
    success: #222,
    info: #fff,
    warning: #320,
    danger: #fff,
    accent: #fff,
    light: #fff,
    dark: #000,
  ),
);

$color: map-get($theme, types);
$cont: map-get($theme, contrast);

$bgColor: (
  "default" #fff map-get($theme, text),
  "primary" map-get($color, primary) #fff,
  "success" map-get($color, success) #fff,
  "info" map-get($color, info) #fff,
  "warning" map-get($color, warning) #fff,
  "danger" map-get($color, danger) #fff,
  "theme" map-get($theme, color) #fff,
  "secondary" map-get($theme, accent) #fff
);
