header.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  position: fixed;
  box-shadow: 0 0 8px rgba(map-get($color, dark), 0.4);
  // background: map-get($theme, header-bg);
  .navbar {
    padding: 0;
    // font-size: 20px;
    font-size: 18px;
    @extend %animateFast;
    min-height: $navbar-height;
    @media only screen and (max-width: 1280px) and (min-width: 1200px) {
      font-size: 18px;
    }
    // @media only screen and (max-width: 767px) {
    //   padding: 0 15px;
    // }

    &-brand {
      max-width: 125px;
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
      @include media-breakpoint-down(md) {
        margin-left: 25px;
      }

      @media only screen and (max-width: 767px) {
        padding: 0;
        max-width: 95px;
        // min-height: 87px;
        // top: 0;
        // margin: 0;
        // z-index: 2;
        // width: 100%;
        // position: sticky;
        // text-align: left;
        // max-width: initial;
        // padding-left: 30px;
        // background: map-get($color, light);
        // & > img {
        // }
      }
      @media only screen and (max-width: 567px) {
        max-width: 90px;
        padding: 10px 3px !important;
      }
    }
    &-toggler {
      right: 0;
      top: 25px;
      z-index: 5;
      padding: 0;
      width: 36px;
      height: 36px;
      position: absolute;
      border: 0 none transparent;
      &:hover,
      &:focus {
        outline: 0 none transparent;
      }
      &:-moz-focusring {
        outline: 0 none transparent;
      }
      &-icon {
        width: 100%;
        height: 100%;
        background: none;
        position: relative;
        &,
        &:before,
        &:after {
          transition: all ease-in 0.3s;
        }

        &:after,
        &:before {
          left: 0;
          width: 100%;
          height: 3px;
          content: "";
          position: absolute;
          top: calc(50% - 1px);
          background: map-get($theme, header-link);
        }
        &:before {
          box-shadow: 0 13px map-get($theme, header-link), 0 -13px map-get($theme, header-link);
        }
      }
      &[aria-expanded="true"] {
        .navbar-toggler-icon {
          &:before {
            transform: rotate(45deg);
            box-shadow: 0 0 transparent;
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
      @media only screen and (max-width: 767px) {
        position: sticky;
        margin-left: auto;
        margin-top: -85px;
      }
    }

    .nav {
      &-item {
        padding: 0;
        width: max-content;
        // &.ico {
        //   padding: 7px 15px;
        // }
        @media only screen and (min-width: 375px) {
          padding: 0 15px;
          // &.ico {
          //   padding: 7px 17px;
          // }
        }
        @media only screen and (max-width: 767px) {
          padding: 0;
        }
        @media only screen and (max-width: 1200px) and (min-width: 991px) {
          // padding: 0 12px;
          padding: 0 5px;
          font-size: 17px;
        }
      }
      &-link {
        padding: 6px 1px; // 2rem;
        &,
        &:before {
          @extend %animateFast;
        }
        &:before {
          left: 0;
          right: 0;
          width: 0%;
          bottom: 0;
          height: 3px;
          content: "";
          margin: 0 auto;
          position: absolute;
          border-radius: 3px;
          background: map-get($color, primary);
        }
      }
    }
    .btn {
      text-align: center;
      &-link {
        width: 47px;
        height: 44px;
        padding: 1px;
        font-size: 28px;
      }
      &-lg {
        height: 50px;
        font-size: 17px;
        min-width: 180px;
        padding: 15px 12px;
      }
    }

    &-collapse {
      .navbar-nav {
        &.subBottom {
          display: none;
        }
      }
    }

    // /* LIGHT */
    &-light {
      .navbar-nav {
        .nav-link {
          margin-left: 3px;
          margin-right: 3px;
          position: relative;
          color: map-get($color, primary);
          &:hover,
          &:focus,
          &.active {
            &:before {
              width: 100%;
            }
          }
        }
      }
      .btn-link {
        color: map-get($color, dark);
        background: map-get($theme, gray);
        .i {
          &-light {
            display: none;
          }
        }
      }
    }
    // /* DARK */
    // &-dark {
    //   &.bg-dark {
    //     background: #151515 !important;
    //   }
    //   .navbar-toggler {
    //     &-icon {
    //       &:before,
    //       &:after {
    //         background: map-get($color, light);
    //       }
    //       &:before {
    //         box-shadow: 0 13px map-get($color, light), 0 -13px map-get($color, light);
    //       }
    //     }
    //   }
    //   .navbar-nav {
    //     .nav-link {
    //       color: map-get($color, light);
    //       &:hover,
    //       &:focus {
    //         // border-radius: 4px;
    //         background: rgba(map-get($color, light), 0.16);
    //       }
    //     }
    //   }
    //   .btn-link {
    //     background: #151515;
    //     color: map-get($color, light);
    //     .i {
    //       &-dark {
    //         display: none;
    //       }
    //     }
    //   }
    // }

    @media only screen and (max-width: 991px) {
      padding: 0;
      &-toggler {
        margin-right: 28px;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 22px;
      min-height: 86px;
      text-align: center;
      .container-fluid {
        overflow: auto;
        overflow: scroll;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        max-height: calc(100vh - 52px);
      }
      /*  &-brand {
        // padding: 5px 0;
        padding: 6px 2px;
      } */
      .nav-item,
      .nav-link {
        padding: 3px 0;
        // font-size: 22px;
        color: map-get($theme, color);
      }
      &-collapse {
        padding: 0 28px;
        // background-color: map-get($theme, gray);
        .navbar-nav {
          align-items: center;
          padding-bottom: 15px;
          .nav-link {
            @include font($fontL);
            color: map-get($color, dark) !important;
          }
          &.subBottom {
            display: flex;
            align-items: center;
            border-top: 2px solid map-get($theme, header-link);
          }
        }
      }
      &.mainNav {
        .navbar-nav {
          &.mr-2 {
            margin: 0 !important;
          }
          .nav-item {
            width: 100%;
          }
          .nav-link {
            padding: 15px 0;
            font-size: 22px;
            border-radius: 4px;
            &:before {
              display: none;
            }
            &:hover,
            &:focus,
            &.active,
            &:active {
              background: #e5dfed;
              @media only screen and (max-width: 767px) {
                background: #ccc7d3;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      &.bg-light {
        background: map-get($theme, header-tp) !important;
      }
      &-brand {
        padding: 8px 5px;
        margin-left: 5px;
        background: map-get($color, light);
      }
      &-toggler {
        margin: 0;
        top: 24px;
        right: 8px;
        width: 40px;
        height: 40px;
        z-index: 1031;
        padding: 0 10px;
        position: fixed;
        &-icon {
          &:before {
            box-shadow: 0 7px map-get($theme, header-link), 0 -7px map-get($theme, header-link);
          }
        }
      }
    }

    &.subNav {
      padding: 0;
      min-height: 40px;
      line-height: 20px;
      background: map-get($theme, header-tp);
      .container-fluid {
        padding: 0 30px;
        @media only screen and (max-width: 767px) {
          padding: 0 5px;
        }
      }
      .navbar-nav {
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .nav-item {
          .nav-link {
            font-size: 16px;
            padding: 7px 35px 8px 35px;
            color: map-get($theme, header-text);
            &:hover,
            &:focus,
            &:active {
              background: rgba(map-get($color, dark), 0.08);
            }
            @media only screen and (max-width: 767px) {
              font-size: 12px;
              padding: 9px 15px;
            }
            @media only screen and (max-width: 375px) {
              padding: 9px 4px;
            }
          }

          &:last-child {
            margin-right: -35px;
            @media only screen and (max-width: 1439px) {
              margin-right: -20px;
            }
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .nav-link {
              padding-left: 15px;
              padding-right: 15px;
            }
            &:last-child {
              margin-right: -15px;
            }
          }
        }
      }

      @include media-breakpoint-down(lg) {
        .nav-fill {
          .nav-item {
            text-align: left;
          }
        }
      }

      @media only screen and (max-width: 767px) {
        top: 0;
        // right: 0;
        z-index: 4;
        position: absolute;
        // padding: 1px 32px 1px 0;
        // width: calc(100vw - 100px);
        right: 25px;
        padding: 1px 1px 1px 0;
        width: calc(100vw - 137px);
        .container-fluid {
          padding: 0;
        }
        .navbar {
          &-nav {
            display: block;
            .nav-item {
              padding: 0;
              width: 100%;
              &,
              .nav-link {
                font-size: 10px;
                line-height: 15px;
              }
              .nav-link {
                padding: 3px 5px;
                @include font($fontM);
                text-transform: uppercase;
                color: map-get($color, dark);
              }
            }
          }
        }
      }
      @media only screen and (max-width: 567px) {
        right: 40px;
      }
      @media only screen and (min-width: 330px) and (max-width: 767px) {
        padding-right: 15px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    box-shadow: 0 6px 12px rgba(map-get($color, dark), 0.2);
  }

  .info-banner {
    background: map-get($color, accent);
    color: map-get($color, light);
    font-size: 20px;
    line-height: 35px;
    height: 100%;
    min-height: 50px;
    display: flex;
    position: relative;
    text-align: left;
    .item {
      width: 100%;
      height: 100%;
      display: inline-block;
      padding: 6px 10px;
      text-align: left;
      margin: 0 60px;
      .learnBtn {
        margin-left: 5px;
        color: map-get($theme, write);
        text-decoration: underline;
        text-transform: uppercase;
        @include font($fontM);
      }
    }
    .logo {
      display: inline-block;
      position: absolute;
      margin: 0 10px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 17px;
      padding: 0 20px;
      .item {
        margin: 0 40px;
      }
      .logo {
        left: 20px;
      }
    }
    @media only screen and (max-width: 991px) {
      line-height: 26px;
      .item {
        .learnBtn {
          margin-left: 0;
        }
      }
      .logo {
        top: 10px;
      }
    }
    @media only screen and (max-width: 576px) {
      font-size: 15px;
      line-height: 20px;
      .logo {
        top: 5px;
      }
    }
    @media only screen and (max-width: 414px) {
      .logo {
        top: 20px;
      }
    }
  }
}
